import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'pmsProviderInput',
    'pmsQuestionsContainer',
    'otherPMSProviderContainer',
    'usingBluetentReservationAPIContainer',
    'sendingStayCycleEmailsContainer',
  ]

  connect() {
    this.onPMSProviderChange()
  }

  onPMSProviderChange() {
    const isNone = $(this.pmsProviderInputTarget).val() === '';
    const isOther = $(this.pmsProviderInputTarget).val() === 'other';
    const isEscapia = $(this.pmsProviderInputTarget).val() === 'escapia';
    const isStreamline = $(this.pmsProviderInputTarget).val() === 'streamline'

    if (isNone) {
      $(this.pmsQuestionsContainerTarget).hide()
    } else {
      $(this.pmsQuestionsContainerTarget).show()
    }

    if (isOther) {
      $(this.otherPMSProviderContainerTarget).show()
    } else {
      $(this.otherPMSProviderContainerTarget).hide()
    }

    if (isEscapia || isStreamline) {
      $(this.usingBluetentReservationAPIContainerTarget).show()
    } else {
      $(this.usingBluetentReservationAPIContainerTarget).hide()
    }

    if (isStreamline) {
      $(this.sendingStayCycleEmailsContainerTarget).show()
    } else {
      $(this.sendingStayCycleEmailsContainerTarget).hide()
    }
  }
}
