import { Controller } from 'stimulus'

export default class extends Controller {
  onSubmit(e) {
    const response = e.detail[0];
    const json = JSON.parse(response.responseText)

    document.dispatchEvent(new CustomEvent('unassigned_access_points_refresh', {
      detail: json
    }))
    document.dispatchEvent(new CustomEvent('close_modal'))
  }
}
