import { Controller } from 'stimulus'
import { Confetti } from '../../utilities/confetti'

export default class extends Controller {
  static targets = [
    'status',
    'confetti'
  ]

  connect() {
    this.startPolling()
    this.setupCompleted = false
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 1500)
  }

  poll() {
    const url = this.data.get('url')
    
    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (!this.setupCompleted && json.setup_completed) {
          this.setupCompleted = true
          this.showConfetti()
          clearInterval(this.interval)
        }

        $(this.statusTarget).html(json.status_html)
      })
  }

  showConfetti() {
    const confetti = new Confetti(this.confettiTarget)
    confetti.animate()
  }
}
