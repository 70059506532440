import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'emailMarketingProviderInput',
    'otherEmailMarketingProviderContainer',
    'pmsProviderInput',
    'otherPMSProviderContainer',
    'howHeardSelect',
    'howHeardSpecifyContainer'
  ]

  connect() {
    this.toggleOtherEmailMarketingProvider()
    this.toggleOtherPMSProvider()
    this.toggleHowHeard()
  }

  toggleOtherEmailMarketingProvider() {
    if ($(this.emailMarketingProviderInputTarget).val() === 'other') {
      $(this.otherEmailMarketingProviderContainerTarget).show()
    } else {
      $(this.otherEmailMarketingProviderContainerTarget).hide()
    }
  }

  toggleOtherPMSProvider() {
    if ($(this.pmsProviderInputTarget).val() === 'other') {
      $(this.otherPMSProviderContainerTarget).show()
    } else {
      $(this.otherPMSProviderContainerTarget).hide()
    }
  }

  toggleHowHeard() {
    const value = $(this.howHeardSelectTarget).val().split('_')[1]

    if(this.data.get('options-with-specify').indexOf(value) > -1) {
      $(this.howHeardSpecifyContainerTarget).show()
    } else {
      $(this.howHeardSpecifyContainerTarget).hide()
    }
  }
}
