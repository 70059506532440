import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'imageInput',
    'imageInputHidden',
    'imageThumbnail',
    'thumbnailIcon',
    'thumbnailButton',
    'imageBlob'
  ]

  connect() {
    this.setThumbnail()

    // Events used to update the image when an image is selected from the gallery
    const attributeName = this.data.get('attribute-name')
    const imageAttributeName = this.data.get('image-attribute-name')
    document.addEventListener(attributeName + '_' + imageAttributeName + '_clear_main_image', this.clearMainImage.bind(this))
    document.addEventListener(attributeName + '_' + imageAttributeName + '_update_hidden_input_image_blob', this.updateHiddenInputImageBlob.bind(this))
  }

  onImageChange() {
    // remove the hidden input because we don't need it anymore
    // it's only there for when we reload the page after an error
    // to ensure the image gets resubmitted if the user doesn't change anything
    // but if they do change the file after getting an error, without this,
    // the original file would have been submitted instead of the new one
    // because this hidden field (which has the signed id of the original file)
    // would have taken precedence over the hidden field that the
    // ActiveStorage DirectUpload code would write when the user changes the file
    // the reason is simply that the ActiveStorage hidden field precedes this hidden
    // field in the HTML and has the same name, so when we submit the last input
    // with this name will be used.
    this.clearSelectedImageHiddenInput()

    const file = this.imageInputTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('image-blob-path', URL.createObjectURL(file))

    this.setHiddenInputBlob(URL.createObjectURL(file))

    this.setThumbnail()
  }

  updateHiddenInputImageBlob(event) {
    this.setHiddenInputBlob(event.detail.blobUrl)
  }

  // Updates the hidden input which is used by preview with the blob ID
  setHiddenInputBlob(url) {
    this.imageBlobTarget.value = url
    const event = new Event('input', { bubbles: true });
    this.imageBlobTarget.dispatchEvent(event);
  }

  // Sets the thumbnail of the selected image in the image gallery
  setThumbnail() {
    const imageUrl = this.data.get('image-blob-path')
    const imageName = this.data.get('image-name')

    $(this.thumbnailIconTarget).find('img').remove()

    if (imageUrl && imageUrl !== `/rails/active_storage/blobs//${imageName}`) {
      const thumb = document.createElement('img')
      thumb.src = imageUrl
      thumb.className = 'w-full h-full object-cover'

      $(this.thumbnailIconTarget).append(thumb)
      $(this.thumbnailButtonTarget).show()
      $(this.thumbnailIconTarget).show()
    } else {
      $(this.thumbnailIconTarget).find('img').remove()
      $(this.thumbnailButtonTarget).hide()
      $(this.thumbnailIconTarget).hide()
    }
  }

  clearSelectedImageHiddenInput() {
    const attributeName = this.data.get('attribute-name')
    const imageAttributeName = this.data.get('image-attribute-name')
    const selectedImageFrame = document.getElementById(attributeName + '_' + imageAttributeName + '_image_gallery_selected_image')
    selectedImageFrame.innerHTML = '';
  }

  clearMainImage() {
    $(this.imageInputTarget).val(null)
  }

  clearImage() {
    // Clear both the image input and the hidden input
    this.clearMainImage()
    this.clearSelectedImageHiddenInput()
    this.data.set('image-blob-path', '')
    this.setHiddenInputBlob('')

    // remove thumbnail
    this.setThumbnail()
  }
}
