import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'enabledCheckbox',
    'enabledToggle',
    'contentArea'
  ]

  connect() {
    if (this.enabledCheckboxTarget.checked) {
      $(this.enabledToggleTarget).addClass('enabled')
      $(this.contentAreaTarget).show()
    }
  }

  toggle(e) {
    if (this.enabledCheckboxTarget.checked) {
      $(this.enabledToggleTarget).removeClass('enabled')
      this.enabledCheckboxTarget.checked = false
      $(this.contentAreaTarget).slideUp()
    } else {
      $(this.enabledToggleTarget).addClass('enabled')
      this.enabledCheckboxTarget.checked = true
      $(this.contentAreaTarget).slideDown()
    }
  }
}
