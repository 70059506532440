import { Controller } from 'stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'preview',
    'desktopPreviewButton',
    'mobilePreviewButton',
    'previewSplashPage',
    'successText',
    'buttonText',
    'instagramEnabled',
    'instagramUrlContainer',
    'instagramUrl',
    'facebookEnabled',
    'facebookUrl',
    'facebookPixelEnabled',
    'facebookPixelIdContainer',
    'facebookPixelId'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }

  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    this.refreshPreview()
    this.showDesktopPreview()
    this.toggleInstagramUrl()
    this.toggleFacebookPixelId()
  }

  onChange(e) {
    this.debouncedRefreshPreview()
    this.toggleInstagramUrl()
    this.toggleFacebookPixelId()
  }

  toggleInstagramUrl() {
    $(this.instagramUrlContainerTarget).toggle(this.instagramEnabledTarget.checked)
  }

  toggleFacebookPixelId() {
    $(this.facebookPixelIdContainerTarget).toggle(this.facebookPixelEnabledTarget.checked)
  }

  showDesktopPreview() {
    $(this.desktopPreviewButtonTarget).removeClass('bg-white').addClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).removeClass('bg-gray-200').addClass('bg-white')
    $(this.previewTargets).css('width', '').addClass('w-full')
  }

  showMobilePreview() {
    $(this.desktopPreviewButtonTarget).addClass('bg-white').removeClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).addClass('bg-gray-200').removeClass('bg-white')
    $(this.previewTargets).css('width', '23rem').removeClass('w-full')
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
  }

  get previewParams() {
    return {
      splash_page_id: this.previewSplashPageTarget.value,
      success_text: this.successTextTarget.value,
      button_text: this.buttonTextTarget.value,
      instagram_url: this.instagramEnabledTarget.checked ? (this.instagramUrlTarget.value || 'http://www.instagram.com') : null,
      facebook_pixel_id: this.facebookPixelEnabledTarget.checked ? this.facebookPixelIdTarget.value : null
    }
  }
}
