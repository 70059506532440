import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'fileInput',
    'fileInputHidden',
    'clearButton',
    'fileName',
    'fileIcon',
    'uploadIcon',
    'placeholder',
    'filenameInput'
  ]

  connect() {
    this.toggleName()
  }

  onFileChange() {
    // remove the hidden input because we don't need it anymore
    // it's only there for when we reload the page after an error
    // to ensure the image gets resubmitted if the user doesn't change anything
    // but if they do change the file after getting an error, without this,
    // the original file would have been submitted instead of the new one
    // because this hidden field (which has the signed id of the original file)
    // would have taken precedence over the hidden field that the
    // ActiveStorage DirectUpload code would write when the user changes the file
    // the reason is simply that the ActiveStorage hidden field precedes this hidden
    // field in the HTML and has the same name, so when we submit the last input
    // with this name will be used.

    if (this.hasFileInputHiddenTarget) {
      $(this.fileInputHiddenTarget).remove()
    }

    const file = this.fileInputTarget.files[0]

    if (!file) {
      return
    }

    this.data.set('file-name', file.name)
    this.toggleName()
  }

  toggleName() {
    const fileName = this.data.get('file-name')

    if (fileName) {
      $(this.clearButtonTarget).show()
      $(this.fileIconTarget).show()
      $(this.uploadIconTarget).hide()
      $(this.fileNameTarget).text(fileName).show()
      $(this.placeholderTarget).hide()
      $(this.filenameInputTarget).val(fileName)
    } else {
      $(this.clearButtonTarget).hide()
      $(this.fileIconTarget).hide()
      $(this.uploadIconTarget).show()
      $(this.fileNameTarget).text('').hide()
      $(this.placeholderTarget).show()
      $(this.filenameInputTarget).val('')
    }
  }

  clearFile() {
    if (this.hasFileInputHiddenTarget) {
      $(this.fileInputHiddenTarget).find('input').val(null)
    }

    $(this.fileInputTarget).val(null)
    this.data.set('file-name', '')
    this.toggleName()
  }
}
