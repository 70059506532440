export default {
  base: {
    color: '#000000',
    fontFamily: 'Inter, Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#c81e1e',
    iconColor: '#c81e1e'
  }
}
