import { Controller } from 'stimulus'
import intlTelInput from 'intl-tel-input'
import _ from 'lodash'
/* global grecaptcha */

export default class extends Controller {
  static targets = [
    'message',
    'rateBody',
    'rateHeadline',
    'previewReview',
    'previewSplashPage',
    'goodReviewBody',
    'goodReviewHeadline',
    'previewReviewThanks',
    'badReviewBody',
    'badReviewHeadline',
    'previewFeedbacks',
    'facebookEnabled',
    'facebookUrl',
    'facebookUrlWrapper',
    'googleEnabled',
    'googleUrl',
    'googleUrlWrapper',
    'tripadvisorEnabled',
    'tripadvisorUrl',
    'tripadvisorUrlWrapper',
    'listingUrlEnabled',
    'goodReviewThresholdInput',
    'goodReviewThreshold',
    'form',
    'previewLinkSent',
    'sendPreview',
    'daysSinceWifiConnect',
    'phoneNumber',
    'phoneNumberCountry',
    'phoneNumberInput',
    'phoneNumberError',
    'previewRentalProperty',
  ]

  onFacebookEnabledToggle() {
    const facebookUrlWrapperTarget = $(this.facebookUrlWrapperTarget)

    if (this.facebookEnabledTarget.checked) {
      facebookUrlWrapperTarget.removeClass('hidden')
    } else {
      facebookUrlWrapperTarget.addClass('hidden')
      $(this.facebookUrlTarget).val('')
    }

    this.refreshReviewThanksPreview()
  }

  onGoogleEnabledToggle() {
    const googleUrlWrapperTarget = $(this.googleUrlWrapperTarget)

    if (this.googleEnabledTarget.checked) {
      googleUrlWrapperTarget.removeClass('hidden')
    } else {
      googleUrlWrapperTarget.addClass('hidden')
      $(this.googleUrlTarget).val('')
    }

    this.refreshReviewThanksPreview()
  }

  onTripadvisorEnabledToggle() {
    const tripadvisorUrlWrapperTarget = $(this.tripadvisorUrlWrapperTarget)

    if (this.tripadvisorEnabledTarget.checked) {
      tripadvisorUrlWrapperTarget.removeClass('hidden')
    } else {
      tripadvisorUrlWrapperTarget.addClass('hidden')
      $(this.tripadvisorUrlTarget).val('')
    }

    this.refreshReviewThanksPreview()
  }

  onListingUrlEnabledToggle() {
    this.refreshReviewThanksPreview()
  }

  onGoodReviewThresholdChange() {
    $(this.goodReviewThresholdTargets).text($(this.goodReviewThresholdInputTarget).val())
  }

  connect() {
    this.initialize()
    this.initializePhoneNumber()

    this.onFacebookEnabledToggle()
    this.onGoogleEnabledToggle()
    this.onTripadvisorEnabledToggle()
    this.onListingUrlEnabledToggle()
    this.onGoodReviewThresholdChange()

    this.refreshReviewPreview()
    this.refreshFeedbacksPreview()
  }

  onSplashPageChange(e) {
    $(this.previewSplashPageTargets).val(e.target.value)

    this.refreshReviewPreview()
    this.refreshReviewThanksPreview()
    this.refreshFeedbacksPreview()
  }

  onRentalPropertyChange(e) {
    $(this.previewRentalPropertyTargets).val(e.target.value)

    this.refreshReviewPreview()
    this.refreshReviewThanksPreview()
    this.refreshFeedbacksPreview()
  }

  onReviewChange() {
    this.debouncedRefreshReviewPreview()
  }

  onReviewThanksChange() {
    this.debouncedRefreshReviewThanksPreview()
  }

  onFeedbackChange() {
    this.debouncedRefreshFeedbacksPreview()
  }

  initialize() {
    this.debouncedRefreshReviewPreview = _.debounce(() => {
      this.refreshReviewPreview()
    }, 350)

    this.debouncedRefreshReviewThanksPreview = _.debounce(() => {
      this.refreshReviewThanksPreview()
    }, 350)

    this.debouncedRefreshFeedbacksPreview = _.debounce(() => {
      this.refreshFeedbacksPreview()
    }, 350)
  }

  refreshReviewPreview() {
    const src = `${this.data.get('preview-review-path')}?${$.param(this.previewReviewParams)}`
    $(this.previewReviewTarget).attr('src', src)
  }

  refreshReviewThanksPreview() {
    const src = `${this.data.get('preview-review-thanks-path')}?${$.param(this.previewReviewThanksParams)}`
    $(this.previewReviewThanksTarget).attr('src', src)
  }

  refreshFeedbacksPreview() {
    const src = `${this.data.get('preview-feedbacks-path')}?${$.param(this.previewFeedbacksParams)}`
    $(this.previewFeedbacksTarget).attr('src', src)
  }

  initializePhoneNumber() {
    if (this.hasPhoneNumberInputTarget) {
      this.iti = intlTelInput(this.phoneNumberInputTarget, {
        nationalMode: true,
        utilsScript: require('intl-tel-input/build/js/utils.js'),
        initialCountry: this.data.get('phone-number-initial-country') || 'us'
      })
      this.phoneNumberCountryTarget.value = this.iti.getSelectedCountryData().iso2
      this.phoneNumberTarget.value = this.iti.getNumber()
    }
  }

  onPhoneNumberKeyup() {
    this.phoneNumberCountryTarget.value = this.iti.getSelectedCountryData().iso2
    this.phoneNumberTarget.value = this.iti.getNumber()
  }

  onSendPreviewSms(e) {
    e.preventDefault()

    const phoneNumber = this.iti.getNumber()
    const recaptchaValue = grecaptcha.getResponse();

    const postData = {...this.previewCampaignParams, 'g-recaptcha-response': recaptchaValue};

    if (!_.isEmpty(phoneNumber) && this.iti.isValidNumber()) {
      $(this.phoneNumberErrorTarget).hide()

      $.post(this.data.get('preview-campaign-url'), postData).done((response) => {
        if (response.saved) {
          this.showPreviewSendConfirmation()
          grecaptcha.reset()
        } else {
          this.refresh(response.form_html)
        }
      })

    } else {
      $(this.phoneNumberErrorTarget).show()
    }
  }

  showPreviewSendConfirmation() {
    $(this.sendPreviewTarget).hide()
    $(this.previewLinkSentTarget).show()

    setTimeout(() => {
      $(this.previewLinkSentTarget).hide()
      $(this.sendPreviewTarget).show()
    }, 2000)
  }

  refresh(formHtml) {
    $(this.formTarget).html(formHtml)

    this.connect()
  }

  get previewReviewParams() {
    return {
      splash_page_id: this.hasPreviewSplashPageTarget && this.previewSplashPageTarget.value,
      rental_property_id: this.hasPreviewRentalPropertyTarget && this.previewRentalPropertyTarget.value,
      rate_headline: this.rateHeadlineTarget.value,
      rate_body: this.rateBodyTarget.value
    }
  }

  get previewReviewThanksParams() {
    return {
      splash_page_id: this.hasPreviewSplashPageTarget && this.previewSplashPageTarget.value,
      rental_property_id: this.hasPreviewRentalPropertyTarget && this.previewRentalPropertyTarget.value,
      good_review_headline: this.goodReviewHeadlineTarget.value,
      good_review_body: this.goodReviewBodyTarget.value,
      facebook_url: this.facebookEnabledTarget.checked ? (this.facebookUrlTarget.value || 'https://www.stayfi.com') : null,
      google_url: this.googleEnabledTarget.checked ? (this.googleUrlTarget.value || 'https://www.stayfi.com') : null,
      tripadvisor_url: this.tripadvisorEnabledTarget.checked ? (this.tripadvisorUrlTarget.value || 'https://www.stayfi.com') : null,
      listing_url_enabled: this.listingUrlEnabledTarget.checked
    }
  }

  get previewFeedbacksParams() {
    return {
      splash_page_id: this.hasPreviewSplashPageTarget && this.previewSplashPageTarget.value,
      rental_property_id: this.hasPreviewRentalPropertyTarget && this.previewRentalPropertyTarget.value,
      bad_review_headline: this.badReviewHeadlineTarget.value,
      bad_review_body: this.badReviewBodyTarget.value
    }
  }

  get previewCampaignParams() {
    return {
      model: {
        days_since_wifi_connect: $(this.daysSinceWifiConnectTarget).val(),
        message: $(this.messageTarget).val(),
        rate_headline: $(this.rateHeadlineTarget).val(),
        rate_body: $(this.rateBodyTarget).val(),
        good_review_threshold: $(this.goodReviewThresholdInputTarget).val(),
        good_review_body: $(this.goodReviewBodyTarget).val(),
        good_review_headline: $(this.goodReviewHeadlineTarget).val(),
        bad_review_body: $(this.badReviewBodyTarget).val(),
        bad_review_headline: $(this.badReviewHeadlineTarget).val(),
        facebook_url: $(this.facebookUrlTarget).val(),
        facebook_url_enabled: $(this.facebookUrlEnabledTarget).val(),
        google_url: $(this.googleUrlTarget).val(),
        google_url_enabled: $(this.googleUrlEnabledTarget).val(),
        tripadvisor_url: $(this.tripadvisorUrlTarget).val(),
        tripadvisor_url_enabled: $(this.tripadvisorUrlEnabledTarget).val(),
        listing_url_enabled: $(this.listingUrlEnabledTarget).val(),
        phone_number: $(this.phoneNumberTarget).val(),
        phone_number_country: $(this.phoneNumberCountryTarget).val(),
        phone_number_input: $(this.phoneNumberInputTarget).val()
      }
    }
  }
}
