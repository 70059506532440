import { Controller } from 'stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = [
    'preview',
    'headerBackground',
    'logo',
    'logoThumbnail',
    'logoHidden',
    'headline',
    'body',
    'background',
    'backgroundThumbnail',
    'backgroundHidden',
    'requiresPassword',
    'requiresPhoneNumber',
    'ageRequirement',
    'precheck18Plus',
    'precheck18PlusContainer',
    'requiresMarketingConsent',
    'marketingConsentTextContainer',
    'marketingConsentText',
    'precheckMarketingConsent',
    'password',
    'form',
    'desktopPreviewButton',
    'mobilePreviewButton',
    'logoScaleFactor',
    'contentTab',
    'styleTab',
    'contentContainer',
    'styleContainer',
    'contentTabUnderline',
    'styleTabUnderline',
    'headerEnabled',
    'headerAndLogoContainer',
    'backgroundOverlayOpacity',
    'mainTextColorInput',
    'buttonBackgroundColorInput',
    'buttonTextColorInput'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }

  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    if (this.data.get('logo-blob-path')) {
      this.data.set('logo-url', this.data.get('logo-blob-path'))
    }

    if (this.data.get('background-blob-path')) {
      this.data.set('background-url', this.data.get('background-blob-path'))
    }

    this.refreshPreview()
    this.togglePasswordInput()
    this.toggleMarketingConsentText()
    this.setThumbnails()
    this.showDesktopPreview()
    this.toggleHeaderAndLogo()
    this.togglePrecheck18PlusContainer()
  }

  showStyleTab(e) {
    e.preventDefault()

    $(this.contentTabUnderlineTarget).removeClass('bg-blue-500').addClass('bg-transparent')
    $(this.styleTabUnderlineTarget).removeClass('bg-transparent').addClass('bg-blue-500')

    $(this.contentTabTarget).removeClass('text-gray-900').addClass('text-gray-500')
    $(this.styleTabTarget).removeClass('text-gray-500').addClass('text-gray-900')

    $(this.contentContainerTarget).addClass('hidden')
    $(this.styleContainerTarget).removeClass('hidden')
  }

  showContentTab(e) {
    e.preventDefault()

    $(this.contentTabUnderlineTarget).addClass('bg-blue-500').removeClass('bg-transparent')
    $(this.styleTabUnderlineTarget).addClass('bg-transparent').removeClass('bg-blue-500')

    $(this.contentTabTarget).addClass('text-gray-900').removeClass('text-gray-500')
    $(this.styleTabTarget).addClass('text-gray-500').removeClass('text-gray-900')

    $(this.contentContainerTarget).removeClass('hidden')
    $(this.styleContainerTarget).addClass('hidden')
  }

  onLogoChange(e) {
    // remove the hidden input because we don't need it anymore
    // it's only there for when we reload the page after an error
    // to ensure the image gets resubmitted if the user doesn't change anything
    // but if they do change the file after getting an error, without this,
    // the original file would have been submitted instead of the new one
    // because this hidden field (which has the signed id of the original file)
    // would have taken precedence over the hidden field that the
    // ActiveStorage DirectUpload code would write when the user changes the file
    // the reason is simply that the ActiveStorage hidden field precedes this hidden
    // field in the HTML and has the same name, so when we submit the last input
    // with this name will be used.
    if (this.hasLogoHiddenTarget) {
      $(this.logoHiddenTarget).remove()
    }

    const file = this.logoTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('logo-url', file && URL.createObjectURL(file))
    this.onChange(e)
  }

  onBackgroundChange(e) {
    // remove the hidden input because we don't need it anymore
    // it's only there for when we reload the page after an error
    // to ensure the image gets resubmitted if the user doesn't change anything
    // but if they do change the file after getting an error, without this,
    // the original file would have been submitted instead of the new one
    // because this hidden field (which has the signed id of the original file)
    // would have taken precedence over the hidden field that the
    // ActiveStorage DirectUpload code would write when the user changes the file
    // the reason is simply that the ActiveStorage hidden field precedes this hidden
    // field in the HTML and has the same name, so when we submit the last input
    // with this name will be used.
    if (this.hasBackgroundHiddenTarget) {
      $(this.backgroundHiddenTarget).remove()
    }

    const file = this.backgroundTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('background-url', file && URL.createObjectURL(file))
    this.onChange(e)
  }

  onChange(e) {
    this.debouncedRefreshPreview()
    this.togglePasswordInput()
    this.toggleMarketingConsentText()
    this.setThumbnails()
    this.toggleHeaderAndLogo()
    this.togglePrecheck18PlusContainer()
  }

  togglePasswordInput() {
    if (this.hasRequiresPasswordTarget && this.hasPasswordTarget) {
      $(this.passwordTarget).toggle(this.requiresPasswordTarget.checked)
    }
  }

  toggleMarketingConsentText() {
    if (this.requiresMarketingConsentTarget.checked) {
      $(this.marketingConsentTextContainerTarget).show()
    } else {
      $(this.marketingConsentTextContainerTarget).hide()
    }
  }

  toggleHeaderAndLogo() {
    if (this.headerEnabledTarget.checked) {
      $(this.headerAndLogoContainerTargets).show()
    } else {
      $(this.headerAndLogoContainerTargets).hide()
    }
  }

  togglePrecheck18PlusContainer() {
    if (this.ageRequirementTarget.value === '18_plus') {
      $(this.precheck18PlusContainerTarget).show()
    } else {
      $(this.precheck18PlusContainerTarget).hide()
    }
  }

  showDesktopPreview() {
    $(this.desktopPreviewButtonTarget).removeClass('bg-white').addClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).removeClass('bg-gray-200').addClass('bg-white')
    $(this.previewTargets).css('width', '').addClass('w-full')
  }

  showMobilePreview() {
    $(this.desktopPreviewButtonTarget).addClass('bg-white').removeClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).addClass('bg-gray-200').removeClass('bg-white')
    $(this.previewTargets).css('width', '23rem').removeClass('w-full')
  }

  setThumbnails() {
    const backgroundUrl = this.data.get('background-url');
    const logoUrl = this.data.get('logo-url')

    if (backgroundUrl) {
      const thumb = document.createElement('img')
      thumb.src = backgroundUrl
      $(this.backgroundThumbnailTarget).html(thumb)
    }

    if (logoUrl) {
      const thumb = document.createElement('img')
      thumb.src = logoUrl
      $(this.logoThumbnailTarget).html(thumb)
    }
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
  }

  get previewParams() {
    return {
      headline: this.headlineTarget.value,
      body: this.bodyTarget.value,
      header_enabled: this.headerEnabledTarget.checked,
      header_background: $(this.headerBackgroundTargets).filter(':checked').val(),
      logo_url: this.data.get('logo-url') || undefined,
      logo_scale_factor: this.logoScaleFactorTarget.value,
      background_overlay_opacity: this.backgroundOverlayOpacityTarget.value,
      background_url: this.data.get('background-url') || undefined,
      requires_password: this.hasRequiresPasswordTarget && this.requiresPasswordTarget.checked,
      requires_phone_number: this.requiresPhoneNumberTarget.checked,
      age_requirement: this.ageRequirementTarget.value,
      precheck_18_plus: this.precheck18PlusTarget.checked,
      requires_marketing_consent: this.requiresMarketingConsentTarget.checked,
      marketing_consent_text: this.marketingConsentTextTarget.value,
      precheck_marketing_consent: this.precheckMarketingConsentTarget.checked,
      main_text_color: this.mainTextColorInputTarget.value,
      button_background_color: this.buttonBackgroundColorInputTarget.value,
      button_text_color: this.buttonTextColorInputTarget.value
    }
  }
}
