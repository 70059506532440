import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'importImage',
    'imageContainer',
    'uploadImageContainer'
  ]

  connect() {
    this.onImportImageChange()
  }

  onImportImageChange() {
    if (this.hasImportImageTarget && this.importImageTarget.checked) {
      if (this.hasImageContainerTarget) {
        $(this.imageContainerTarget).show()
      }

      $(this.uploadImageContainerTarget).hide()
    } else {
      if (this.hasImageContainerTarget) {
        $(this.imageContainerTarget).hide()
      }

      $(this.uploadImageContainerTarget).show()
    }
  }
}
