import ConfettiGenerator from 'confetti-js'

export class Confetti {
  constructor(target) {
    this.target = target
  }

  animate() {
    new ConfettiGenerator({
      target: this.target,
      max: '400',
      size: '2',
      animate: true,
      props: ['circle', 'square', 'triangle'],
      colors: [
        [4, 118, 254],
        [254, 152, 0],
        [136, 255, 146],
        [1, 223, 167],
        [1, 96, 195],
        [160, 196, 255],
        [120, 86, 255]
      ],
      clock: '175',
      rotate: false,
      width: $(window).width(),
      height: $(window).height(),
      start_from_edge: true,
      respawn: false
    }).render()
  }
}
