import { Controller } from 'stimulus'

const STATUS_VERIFIED = 'verified';

export default class extends Controller {
  static targets = ['menuItem']

  connect() {
    this.collapseInstructions();
    this.scrollTo();
  }

  hideAllInstructions() {
    ['dkim', 'dmarc', 'spf'].forEach(target => {
      this.hideTargetInstructions(target);
    });
  }

  removeActiveFromAllMenuItems() {
    this.menuItemTargets.forEach((element) => {
      element.classList.remove('active');
    });
  }

  displayTargetInstructions(target) {
    this.toggleElementVisibility(`#${target}-instructions`, false);
    this.toggleElementVisibility(`#${target}-error-block`, false);
  }

  hideTargetInstructions(target) {
    this.toggleElementVisibility(`#${target}-instructions`, true);
    this.toggleElementVisibility(`#${target}-error-block`, true);
  }

  toggleElementVisibility(selector, shouldHide) {
    const element = document.querySelector(selector);
    element.classList[shouldHide ? 'add' : 'remove']('hidden');
  }

  displayInstructions(event) {
    if (event.currentTarget.classList.contains('active')) {
      return;
    }
    const targetToDisplay = event.currentTarget.dataset.instructions;

    this.hideAllInstructions();
    this.removeActiveFromAllMenuItems();
    event.currentTarget.classList.add('active');
    this.displayTargetInstructions(targetToDisplay);

    this.toggleElementVisibility('#instructions-and-help-section', false);
    document.querySelector('.chevron-header-collection').classList.remove('border-b-1', 'rounded-b-lg');
  }

  collapseInstructions() {
    if (this.menuItemTargets.every((element) => element.dataset.status === STATUS_VERIFIED)) {
      this.toggleElementVisibility('#instructions-and-help-section', true);
      document.querySelector('.chevron-header-collection').classList.add('border-b-1', 'rounded-b-lg');
    }
  }

  scrollTo() {
    if (this.menuItemTargets.every((element) => element.dataset.status === STATUS_VERIFIED)) {
      document.querySelector('#dns-verification-continue').scrollIntoView({ behavior: 'smooth' });
    }
  }
}