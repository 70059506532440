import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'tile', 'domainInput', 'domain', 'dnsProvider', 'continue', 'providerFetchCheck' ]

  connect() {
    document.addEventListener('turbo:frame-render', this.onTurboLoad.bind(this));
  }

  disconnect() {
    document.removeEventListener('turbo:frame-render', this.onTurboLoad.bind(this));
  }

  select(event) {
    this.tileTargets.forEach((element) => {
      element.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
    this.dnsProviderTarget.value = event.currentTarget.dataset.provider
  }

  onChange(event) {
    this.domainTarget.value = event.currentTarget.value
    if (this.domainTarget.value) {
      this.providerFetchCheckTarget.removeAttribute('disabled')
    } else {
      this.providerFetchCheckTarget.setAttribute('disabled', 'disabled')
    }
  }

  onTurboLoad(event) {
    this.setProvider();
    this.setDomain();
    if (document.querySelector('.domain_provider_tiles')) {
      this.continueTarget.classList.remove('hidden')
    }
  }

  setProvider() {
    const activeTile = this.tileTargets.find((element) => {
      return element.classList.contains('active')
    })

    if (activeTile) {
      this.dnsProviderTarget.value = activeTile.dataset.provider
    }
  }

  setDomain() {
    this.domainTarget.value = this.domainInputTarget.value
    this.providerFetchCheckTarget.removeAttribute('disabled')
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('hidden')
    this.buttonTextTarget.classList.add('hidden')
  }
}