import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'

import '@hotwired/turbo-rails'

import 'stylesheets/customer'
import 'controllers/shared'
import 'controllers/customer'

require.context('../images', true)
require('jquery')
require('jquery-ui')

Rails.start()
ActiveStorage.start()
