import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'splashPageFieldset',
    'sharePageFieldset',
    'previousScreenButton',
    'nextScreenButton',
  ]

  connect() {
    if (this.hasErrorOnSharePageFieldset()) {
      this.showSharingPageFieldset()
    }

    if (this.hasErrorOnSplashPageFieldset()) {
      this.showSplashPageFieldset()
    }
  }

  showSharingPageFieldset() {
    $(this.nextScreenButtonTargets).attr('disabled', 'disabled')
    $(this.previousScreenButtonTargets).removeAttr('disabled')

    $(this.sharePageFieldsetTarget).removeClass('hidden')
    $(this.splashPageFieldsetTarget).addClass('hidden')
  }

  showSplashPageFieldset() {
    $(this.previousScreenButtonTargets).attr('disabled', 'disabled')
    $(this.nextScreenButtonTargets).removeAttr('disabled')

    $(this.splashPageFieldsetTarget).removeClass('hidden')
    $(this.sharePageFieldsetTarget).addClass('hidden')
  }

  hasErrorOnSplashPageFieldset() {
    return $(this.splashPageFieldsetTarget).find('.is-invalid').length > 0
  }

  hasErrorOnSharePageFieldset() {
    return $(this.sharePageFieldsetTarget).find('.is-invalid').length > 0
  }
}
