import { Controller } from 'stimulus'
import { loadStripe } from '@stripe/stripe-js'
import Rails from '@rails/ujs'
import stripeStyle from './stripe_elements_style'

export default class extends Controller {
  static targets = [
    'cardInput',
    'cardErrors'
  ]

  connect() {
    this.stripePromise = new Promise((resolve, reject) => {
      loadStripe(this.data.get('stripe-public-key')).then((stripe) => {
        const elements = stripe.elements()
        const cardElement = elements.create('card', { style: stripeStyle })

        cardElement.mount(this.cardInputTarget)
        cardElement.on('change', (event) => {
          this.showCardError(event)
        })

        resolve({ stripe, cardElement })
      })
    })
  }

  showCardError(event) {
    if (event.error) {
      $(this.cardErrorsTarget).text(event.error.message)
    } else {
      $(this.cardErrorsTarget).text('')
    }
  }

  submitForm(e) {
    e.preventDefault()
    Rails.disableElement(e)

    const handleError = (result) => {
      this.showCardError(result)
      Rails.enableElement(e)
    }

    this.stripePromise.then(({ stripe, cardElement }) => {
      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      }).then((result) => {
        if (result.error) {
          handleError(result)
          return
        }

        const paymentMethodId = result.paymentMethod.id

        $.post(this.data.get('submit-url'), {
          billing_automation: {
            payment_method_id: paymentMethodId
          }
        }).done((result) => {
          if (result.error) {
            handleError(result)
            return
          }

          const status = result.payment_intent_status
          if (status === 'requires_action') {
            return stripe.confirmCardPayment(result.payment_intent_client_secret, { payment_method: paymentMethodId }).then((result) => {
              if (result.error || result.paymentIntent.status !== 'succeeded') {
                handleError(result)
              } else {
                this.onSuccess()
              }
            })

          } else if (status === 'requires_payment_method') {
            handleError({ error: { message: 'Your card was declined.' } })
          } else {
            this.onSuccess()
          }
        }).fail((result) => {
          handleError({ error: { message: 'Your submission was not processed. Please try again.' } })
        })
      })
    })
  }

  onSuccess() {
    window.location.href = this.data.get('success-url')
  }
}
