import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'contentContainer',
    'pmsPropertySelect',
    'createPropertyGroupCtaWrapper',
    'cta',
    'searchContainer',
    'bulkModal',
    'bulkRetalPropertyGroupSelect',
    'filterProperties',
    'currentPageProperties',
    'showBulkModalCta'
  ]

  showBulkModal() {
    $(this.bulkModalTarget).show()
  }

  hideBulkModal() {
    $(this.bulkModalTarget).hide()
  }

  bulkAssignPage() {
    this.updateSelection(this.bulkRetalPropertyGroupSelectTarget.value, this.currentPagePropertiesTarget.value.split(','))

    this.pmsPropertySelectTargets.forEach(select => {
      select.value = this.bulkRetalPropertyGroupSelectTarget.value
    })
    this.hideBulkModal()
  }

  bulkAssignFilter() {
    this.updateSelection(this.bulkRetalPropertyGroupSelectTarget.value, this.filterPropertiesTarget.value.split(','))

    this.pmsPropertySelectTargets.forEach(select => {
      select.value = this.bulkRetalPropertyGroupSelectTarget.value
    })
    this.hideBulkModal()
  }

  onPropertyGroupSelect(e) {
    if ($(e.currentTarget).val() === 'add') {
      $(e.currentTarget).val('')
      document.dispatchEvent(new CustomEvent('show_modal', {
        detail: {
          url: this.data.get('new-property-group-url').replace('pms_property_id_placeholder', e.currentTarget.dataset.pmsPropertyId),
        }
      }))
    } else {
      this.updateSelection(e.currentTarget.value, [e.currentTarget.dataset.pmsPropertyId])
    }
  }

  reloadPropertyGroup(e) {
    this.pmsPropertySelectTargets.forEach(select => {
      this.addOptionToSelect(select, e)
      $(select).show()
      if (select.dataset.pmsPropertyId === e.detail.pms_property_id || select.value === 'add') {
        select.value = e.detail.group_id
      }
    })
    this.addOptionToSelect(this.bulkRetalPropertyGroupSelectTarget, e)
    this.updateSelection(e.detail.group_id, [e.detail.pms_property_id])

    $(this.createPropertyGroupCtaWrapperTargets).remove()
    $(this.ctaTarget).removeAttr('disabled')
    $(this.showBulkModalCtaTarget).show()
  }

  addOptionToSelect(select, e) {
    const option = document.createElement('option')
    const optionText = document.createTextNode(e.detail.name)
    const lastOption = select.children[select.children.length - 1]
    option.appendChild(optionText)
    option.setAttribute('value', e.detail.group_id)
    if (lastOption) {
      $(option).insertBefore(lastOption)
    } else {
      select.appendChild(option)
    }
  }

  updateSelection(groupId, pmsPropertyIds) {
    $.ajax({
      url: this.data.get('selections-url'),
      type: 'PUT',
      data: {
        rental_property_group_id: groupId,
        pms_property_ids: pmsPropertyIds.join(',')
      }
    });
  }

  toggleSearchContainer() {
    $(this.searchContainerTarget).slideToggle()
  }
}
