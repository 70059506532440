import { Controller } from 'stimulus'

export default class extends Controller {
  static open = false

  static initialCssProperties = {
    position: 'static',
    left: 'initial',
    top: 'initial',
    right: 'initial',
    bottom: 'initial',
    'z-index': 0,
    'background-color': '',
    cursor: 'zoom-in',
    padding: '10px 0',
  }

  static openCssProperties = {
    position: 'fixed',
    'z-index': 1000000,
    left: '0px',
    top: '0px',
    right: '0px',
    bottom: '0px',
    overflow: 'scroll',
    'background-color': 'rgba(0, 0, 0, .7)',
    cursor: 'zoom-out',
    padding: '30px',
  }

  connect() {
    $(this.element).css(this.constructor.initialCssProperties)
  }

  toggle() {
    if (this.constructor.open) {
      this.constructor.open = false

      $(this.element).css(this.constructor.initialCssProperties)
    } else {
      this.constructor.open = true

      $(this.element).css(this.constructor.openCssProperties)
    }
  }
}
