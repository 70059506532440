import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'displayName',
    'subtext',
    'icon',
    'order',
    'bulkEditorLink'
  ]

  createButton() {
    $.ajax({
      url: this.data.get('path'),
      type: 'POST',
      data: {
        icon: $(this.iconTarget).val(),
        subtext: $(this.subtextTarget).val(),
        display_name: $(this.displayNameTarget).val(),
        order: $(this.orderTarget).val()
      },
      success: (response) => {
        $(this.containerTarget).replaceWith(response.html)

        if (response.home_page_template_tile_id) {
          document.dispatchEvent(new CustomEvent('takeover_show', {
            detail: {
              url: this.data.get('redirect-path').replace('home_page_template_tile_id_placeholder', response.home_page_template_tile_id)
            }
          }))
        }
      }
    })
  }
}
