import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'iconField',
    'button'
  ]

  connect() {
    const iconName = $(this.iconFieldTarget).val()
    $(this.buttonTargets).removeClass('bg-gray-900 text-white').addClass('bg-white text-gray-900')
    const selected = $(this.buttonTargets).filter((i, button) => button.dataset.iconName === iconName)

    if (selected.length) {
      selected.removeClass('bg-white text-gray-900').addClass('bg-gray-900 text-white')
    }
  }

  selectIcon(e) {
    const icon = e.currentTarget.dataset.iconName
    $(this.iconFieldTarget).val(icon)

    $(this.buttonTargets).removeClass('bg-gray-900 text-white').addClass('bg-white text-gray-900')
    $(e.currentTarget).removeClass('bg-white text-gray-900').addClass('bg-gray-900 text-white')
  }
}
