import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['contentContainer']

  onPropertyGroupSelect(e) {
    if ($(e.currentTarget).val() === 'add') {
      $(e.currentTarget).val('')
      document.dispatchEvent(new CustomEvent('show_modal', {
        detail: {
          url: this.data.get('new-property-group-url')
        }
      }))
    }
  }

  reload() {
    const url = this.data.get('form-url')

    fetch(url)
      .then(response => response.text())
      .then(text => {
        $(this.contentContainerTarget).html(text)
      })
  }

}
