import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'emailVerification',
    'passwordVerification',
    'emailInput',
    'customerUpdateForm',
    'customerUpdateCta',
    'otpSendsForm'
  ]

  onPasswordVerified() {
    $(this.emailVerificationTarget).show()
    $(this.passwordVerificationTarget).hide()
    $(this.customerUpdateCtaTarget).hide()
    $(this.customerUpdateFormTarget).attr('disabled', 'disabled')

    if (this.data.get('automatically-send-otp') === 'true') {
      Rails.fire(this.otpSendsFormTarget, 'submit')
    }
  }

  onEmailVerified() {
    $(this.customerUpdateCtaTarget).show()
    $(this.customerUpdateFormTarget).removeAttr('disabled')
  }

  onEmailInput(e) {
    $(this.emailInputTargets).val(e.target.value)
  }

  submit() {
    Rails.fire(this.customerUpdateFormTarget, 'submit')
  }
}
