import { Controller } from 'stimulus'
import { eventTracker } from '../../utilities/customer'

export default class extends Controller {
  connect() {
    window.addEventListener('message', function (message) {
      if (message.data.meetingBookSucceeded) {
        eventTracker.track('hubspot_call.scheduled', { flow: 'ticket_creation', object: 'hubspot_call', action: 'scheduled' })
      }
    })
  }
}
