import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'table'
  ]

  connect() {
    const urlParams = new URLSearchParams(window.location.search)
    const highlightedCell = urlParams.get('highlighted_cell') || this.data.get('highlighted-cell')
    const headerCell = $(`#header-cell-${highlightedCell}`)

    if (highlightedCell && headerCell.length) {
      headerCell.addClass('table-cell--highlited')
      headerCell[0].scrollIntoView()

      $(`.body-cell-${highlightedCell}`).addClass('table-cell--highlited')
    }
  }
}
