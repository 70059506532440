import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'warning']

  static MIN_IMAGE_WIDTH = 800
  static MIN_IMAGE_HEIGHT = 600

  connect() {
    this.checkImageSize()
  }

  checkImageSize() {
    if (this.imageTarget.naturalWidth < this.constructor.MIN_IMAGE_WIDTH && this.imageTarget.naturalHeight < this.constructor.MIN_IMAGE_HEIGHT) {
      $(this.warningTarget).show()
    } else {
      $(this.warningTarget).hide()
    }
  }
}
