import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'preview',
    'previewWrapper',
    'submitButton',
    'titleEnabled',
    'title',
    'introductionTextEnabled',
    'introductionText',
    'customTextEnabled',
    'customText',
    'closingText',
    'closingTextEnabled',
    'bookDirectEnabled',
    'bookDirectTitle',
    'bookDirectDescription',
    'bookDirectLink',
    'bookDirectPromoCodeEnabled',
    'bookDirectPromoCodeCode',
    'bookDirectPromoCodeText',
    'featuredPropertiesEnabled',
    'featuredPropertiesTitle',
    'featuredPropertiesNumberOfProperties',
    'featuredPropertiesPropertyOneName',
    'featuredPropertiesPropertyOneDescription',
    'featuredPropertiesPropertyOneFeat1',
    'featuredPropertiesPropertyOneFeat2',
    'featuredPropertiesPropertyOneFeat3',
    'featuredPropertiesPropertyOneImage',
    'featuredPropertiesPropertyOneLink',
    'featuredPropertiesPropertyTwoName',
    'featuredPropertiesPropertyTwoDescription',
    'featuredPropertiesPropertyTwoFeat1',
    'featuredPropertiesPropertyTwoFeat2',
    'featuredPropertiesPropertyTwoFeat3',
    'featuredPropertiesPropertyTwoImage',
    'featuredPropertiesPropertyTwoLink',
    'featuredPropertiesPropertyThreeName',
    'featuredPropertiesPropertyThreeDescription',
    'featuredPropertiesPropertyThreeFeat1',
    'featuredPropertiesPropertyThreeFeat2',
    'featuredPropertiesPropertyThreeFeat3',
    'featuredPropertiesPropertyThreeImage',
    'featuredPropertiesPropertyThreeLink',
    'featuredPropertiesPropertyFourName',
    'featuredPropertiesPropertyFourDescription',
    'featuredPropertiesPropertyFourFeat1',
    'featuredPropertiesPropertyFourFeat2',
    'featuredPropertiesPropertyFourFeat3',
    'featuredPropertiesPropertyFourImage',
    'featuredPropertiesPropertyFourLink',
    'socialLinksEnabled',
    'socialLinksFacebookEnabled',
    'socialLinksInstagramEnabled',
    'socialLinksTiktokEnabled',
    'socialLinksYoutubeEnabled',
    'socialLinksLinkedinEnabled'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)

    this.boundFormSubmitEvent = this.formSubmitEvent.bind(this)
    this.boundFormSubmitEnd = this.formSubmitEnd.bind(this)
    document.addEventListener('turbo:submit-start',  this.boundFormSubmitEvent)
    document.addEventListener('turbo:submit-end', this.boundFormSubmitEnd)
  }

  formSubmitEvent(event) {
    if($(event.target).data('target') === 'stayAnniversaryEmailForm') {
      this.submitButtonTarget.innerHTML = '<div class="mx-2.5 spinner spinner--button"></div>'
    }
  }

  formSubmitEnd(event) {
    if($(event.target).data('target') === 'stayAnniversaryEmailForm') {
      if (event.detail.success) {
        this.submitButtonTarget.disabled = true
        this.interval = setInterval(() => { this.poll() }, 1500)
      }
    }
  }
  
  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    this.onChange()
  }

  disconnect() {
    document.removeEventListener('turbo:submit-start', this.boundFormSubmitEvent)
    document.removeEventListener('turbo:submit-end', this.boundFormSubmitEnd)

    clearInterval(this.interval)
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
    this.adjustIframeHeight()
  }

  adjustIframeHeight() {
    this.previewTargets.forEach(iframe => {
      iframe.onload = () => {
        iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 140) + 'px'
      }
    })
  }

  get previewParams() {
    const params = {
      title: { 
        title: this.titleTarget.value,
        enabled: this.titleEnabledTarget.checked
      },
      introduction_text: {
        introduction_text: this.introductionTextTarget.value,
        enabled: this.introductionTextEnabledTarget.checked
      },
      custom_text: {
        custom_text: this.customTextTarget.value,
        enabled: this.customTextEnabledTarget.checked
      },
      book_direct: {
        title: this.bookDirectTitleTarget.value,
        description: this.bookDirectDescriptionTarget.value,
        link: this.bookDirectLinkTarget.value,
        promo_code_enabled: this.bookDirectPromoCodeEnabledTarget.checked,
        promo_code_code: this.bookDirectPromoCodeCodeTarget.value,
        promo_code_text: this.bookDirectPromoCodeTextTarget.value,
        enabled: this.bookDirectEnabledTarget.checked,
      },
      featured_properties: {
        title: this.featuredPropertiesTitleTarget.value,
        number_of_properties: this.featuredPropertiesNumberOfPropertiesTarget.value,
        property_one_name: this.featuredPropertiesPropertyOneNameTarget.value,
        property_one_description: this.featuredPropertiesPropertyOneDescriptionTarget.value,
        property_one_image: this.featuredPropertiesPropertyOneImageTarget.value,
        property_one_feat1: this.featuredPropertiesPropertyOneFeat1Target.value,
        property_one_feat2: this.featuredPropertiesPropertyOneFeat2Target.value,
        property_one_feat3: this.featuredPropertiesPropertyOneFeat3Target.value,
        property_one_link: this.featuredPropertiesPropertyOneLinkTarget.value,
        property_two_name: this.featuredPropertiesPropertyTwoNameTarget.value,
        property_two_description: this.featuredPropertiesPropertyTwoDescriptionTarget.value,
        property_two_image: this.featuredPropertiesPropertyTwoImageTarget.value,
        property_two_feat1: this.featuredPropertiesPropertyTwoFeat1Target.value,
        property_two_feat2: this.featuredPropertiesPropertyTwoFeat2Target.value,
        property_two_feat3: this.featuredPropertiesPropertyTwoFeat3Target.value,
        property_two_link: this.featuredPropertiesPropertyTwoLinkTarget.value,
        property_three_name: this.featuredPropertiesPropertyThreeNameTarget.value,
        property_three_description: this.featuredPropertiesPropertyThreeDescriptionTarget.value,
        property_three_image: this.featuredPropertiesPropertyThreeImageTarget.value,
        property_three_feat1: this.featuredPropertiesPropertyThreeFeat1Target.value,
        property_three_feat2: this.featuredPropertiesPropertyThreeFeat2Target.value,
        property_three_feat3: this.featuredPropertiesPropertyThreeFeat3Target.value,
        property_three_link: this.featuredPropertiesPropertyThreeLinkTarget.value,
        property_four_name: this.featuredPropertiesPropertyFourNameTarget.value,
        property_four_description: this.featuredPropertiesPropertyFourDescriptionTarget.value,
        property_four_image: this.featuredPropertiesPropertyFourImageTarget.value,
        property_four_feat1: this.featuredPropertiesPropertyFourFeat1Target.value,
        property_four_feat2: this.featuredPropertiesPropertyFourFeat2Target.value,
        property_four_feat3: this.featuredPropertiesPropertyFourFeat3Target.value,
        property_four_link: this.featuredPropertiesPropertyFourLinkTarget.value,
        enabled: this.featuredPropertiesEnabledTarget.checked
      },
      closing_text: {
        closing_text: this.closingTextTarget.value,
        enabled: this.closingTextEnabledTarget.checked
      }
    }

    if (this.hasSocialLinksEnabledTarget) {
      params.social_links = {
        enabled: this.socialLinksEnabledTarget.checked,
        facebook_enabled: (this.hasSocialLinksFacebookEnabledTarget ? this.socialLinksFacebookEnabledTarget.checked : false),
        instagram_enabled: (this.hasSocialLinksInstagramEnabledTarget ? this.socialLinksInstagramEnabledTarget.checked : false),
        tiktok_enabled: (this.hasSocialLinksTiktokEnabledTarget ? this.socialLinksTiktokEnabledTarget.checked : false),
        youtube_enabled: (this.hasSocialLinksYoutubeEnabledTarget ? this.socialLinksYoutubeEnabledTarget.checked : false),
        linkedin_enabled: (this.hasSocialLinksLinkedinEnabledTarget ? this.socialLinksLinkedinEnabledTarget.checked : false)
      }
    }

    return params
  }

  poll() {
    const url = this.data.get('status-check-path')
    
    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (json.campaign_monitor_automation_id) {
          clearInterval(this.interval)
          window.location.href = json.redirect_path; 
        }
      })
  }
}
