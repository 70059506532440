import { Controller } from 'stimulus'


export default class extends Controller {
  static targets = [
    'calendlyContainer',
    'loadingContainer'
  ]

  connect() {
    $(this.loadingContainerTarget).hide()

    window.addEventListener('message', this.onCalendlyEvent.bind(this))

    Calendly.initInlineWidget({
      url: this.data.get('calendly-event-url'),
      parentElement: this.calendlyContainerTarget,
      prefill: {
        name: this.data.get('rental-manager-name'),
        email: this.data.get('rental-manager-email')
      },
      utm: {}
    })
  }

  disconnect() {
    window.removeEventListener('message', this.onCalendlyEvent.bind(this))
  }

  onCalendlyEvent(e) {
    if (e.data.event === 'calendly.event_scheduled') {
      $(this.loadingContainerTarget).show()

      $.post(this.data.get('create-url'), {
        calendly_event_url: e.data.payload.event.uri
      }).done((response) => {
        location.href = this.data.get('success-url')
      })
    }
  }
}
