import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'calendlyContainer',
    'loadingContainer',
    'skippedContainer',
    'scheduledContainer',
    'unhandledContainer'
  ]

  connect() {
    $(this.loadingContainerTarget).hide()
    $(this.calendlyContainerTarget).hide()

    const skipped = this.data.get('skipped') === 'true';
    const scheduled = this.data.get('scheduled') === 'true'

    if (skipped) {
      $(this.skippedContainerTarget).show()
      $(this.scheduledContainerTarget).hide()
      $(this.unhandledContainerTarget).hide()
    } else if (scheduled) {
      $(this.skippedContainerTarget).hide()
      $(this.scheduledContainerTarget).show()
      $(this.unhandledContainerTarget).hide()
    } else {
      $(this.skippedContainerTarget).hide()
      $(this.scheduledContainerTarget).hide()
      $(this.unhandledContainerTarget).show()
    }

    Calendly.initInlineWidget({
      url: this.data.get('calendly-event-url'),
      parentElement: this.calendlyContainerTarget,
      prefill: {
        name: this.data.get('rental-manager-name'),
        email: this.data.get('rental-manager-email')
      },
      utm: {}
    })

    window.addEventListener('message', this.onCalendlyEvent.bind(this))
  }

  disconnect() {
    window.removeEventListener('message', this.onCalendlyEvent.bind(this))
  }

  showCalendly() {
    this.scheduling = true
    $(this.calendlyContainerTarget).slideDown()

    document.dispatchEvent(new CustomEvent('calendly_shown', {
      detail: { item_number: this.data.get('item-number') }
    }))
  }

  otherCalendlyShown(e) {
    if (e.detail.item_number === this.data.get('item-number')) {
      return
    }

    this.scheduling = false
    $(this.calendlyContainerTarget).slideUp()
  }

  skipScheduling() {
    $(this.loadingContainerTarget).show()
    $(this.calendlyContainerTarget).slideUp()

    $.post(this.data.get('skip-url')).done((response) => {
      $(this.loadingContainerTarget).hide()

      $(this.unhandledContainerTarget).hide()
      $(this.skippedContainerTarget).show()

      document.dispatchEvent(new CustomEvent('switchback_calendar_event_completed'))
    })
  }

  onCalendlyEvent(e) {
    if (!this.scheduling) {
      return
    }

    if (e.data.event === 'calendly.event_scheduled') {
      $(this.loadingContainerTarget).show()

      $.post(this.data.get('scheduling-url'), {
        calendly_event_url: e.data.payload.event.uri
      }).done((response) => {
        $(this.loadingContainerTarget).hide()
        $(this.calendlyContainerTarget).slideUp()

        $(this.unhandledContainerTarget).hide()
        $(this.scheduledContainerTarget).show()

        document.dispatchEvent(new CustomEvent('switchback_calendar_event_completed'))
      })
    }
  }
}
