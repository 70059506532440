import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'ctaCopy', 'ctaCopySuccess'
  ]

  copy() {
    const $temp = $('<input>')
    $('body').append($temp)
    $temp.val(this.data.get('text')).select()
    document.execCommand('copy')
    $temp.remove()

    $(this.ctaCopyTarget).hide()
    $(this.ctaCopySuccessTarget).show()

    setTimeout(() => {
      $(this.ctaCopyTarget).show()
      $(this.ctaCopySuccessTarget).hide()
    }, 1500)
  }
}
