import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cta', 'processWifiSetupCta']

  connect() {
    this.startPolling()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 3000)
  }

  poll() {
    const url = this.data.get('status-url')
    
    if (this.data.get('pending-intent-ids').length > 0) {
      fetch(url)
        .then(response => response.text())
        .then(text => JSON.parse(text))
        .then(json => {

          if (json.finished) {
            clearInterval(this.interval)
            const skipDisableCta = this.hasProcessWifiSetupCtaTarget && $(this.processWifiSetupCtaTarget).attr('disabled') === 'disabled'
            if (!skipDisableCta) {
              $(this.ctaTargets).removeAttr('disabled')
            }

            json.host_co_store_intents.forEach(intent => {
              const $input = $($(`.hostco-store-${intent.rental_property_id}`))
              $input.val(intent.store_url).removeAttr('disabled')

              if (intent.error) {
                $input.after('<p class="text-red-500 text-sm">We encountered an error while setting up your store.</p>')
              }
            })
          }
        })
    } else {
      clearInterval(this.interval)
    }
  }
}

