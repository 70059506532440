import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'preview',
    'previewWrapper',
    'bodyBackgroundColor',
    'mailBackgroundColor',
    'sectionBackgroundColor',
    'titleAndTextColor',
    'buttonBackgroundColor',
    'buttonTextColor',
    'fontFamily',
    'logo'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }
  
  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    if (this.data.get('logo-blob-path')) {
      this.data.set('logo-url', this.data.get('logo-blob-path'))
    }

    this.onChange()
  }

  onLogoChange() {
    const file = this.logoTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('logo-url', file && URL.createObjectURL(file))
    this.onChange()
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`;
    this.previewTargets.forEach(iframe => {
      if (!iframe.classList.contains('ondeck-preview')) return;

      const container = iframe.parentElement;
      container.removeChild(iframe);
      container.appendChild(iframe);
      iframe.src = src;
      Object.assign(iframe, {
        className: 'w-full rounded-lg mx-auto relative ondeck-preview',
        height: '550',
        dataset: {
          target: 'email-marketing-layout-brand-preference.preview',
          hjAllowIframe: 'true'
        }
      });

      iframe.addEventListener('load', () => {
        this.previewTargets.forEach(el => el.classList.toggle('ondeck-preview'));
      });
    });
  }

  get previewParams() {
    return {
      font_family: this.fontFamilyTarget.options[this.fontFamilyTarget.selectedIndex].value,
      title_and_text_color: this.titleAndTextColorTarget.value,
      body_background_color: this.bodyBackgroundColorTarget.value,
      section_background_color: this.sectionBackgroundColorTarget.value,
      mail_background_color: this.mailBackgroundColorTarget.value,
      button_background_color: this.buttonBackgroundColorTarget.value,
      button_text_color: this.buttonTextColorTarget.value,
      logo_url: this.data.get('logo-url') || undefined,
    }
  }
}
