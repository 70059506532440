import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form','cta']

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling(e) {
    $(this.formTarget).find('input').attr('disabled', 'disabled')
    $(this.formTarget).find('button').removeAttr('data-disable-with').attr('disabled', 'disabled')
    this.updateCta(e.detail.progress_percent)
    this.setDetails(e.detail)

    this.interval = setInterval(() => { this.poll() }, 1500)
  }

  poll() {
    const url = this.data.get('url').replace('wifi_setup_intent_id', this.data.get('wifi-setup-intent-id'))

    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        this.updateCta(json.progress_percent)

        if (json.completed) {
          clearInterval(this.interval)

          if (this.data.get('next-path')) {
            window.location.href = this.data.get('next-path')
          } else if (this.data.get('next-modal-path')) {
            this.resetForm()

            document.dispatchEvent(
              new CustomEvent(
                'show_modal',
                {
                  detail: {
                    url: this.data.get('next-modal-path')
                  }
                }
              )
            )
          } else if (this.data.get('next-custom-event')) {
            document.dispatchEvent(new CustomEvent(this.data.get('next-custom-event')))
          }
        }
      })
  }

  updateCta(percent) {
    $(this.ctaTarget).html(`<div class="flex"><div class="mr-2.5 spinner spinner--button"></div>Saving... ${percent}% complete</div>`)
  }

  setDetails(detail) {
    this.data.set('wifi-setup-intent-id', detail.wifi_setup_intent_id)

    if (detail.next_path) {
      this.data.set('next-path', detail.next_path)
    }

    if (detail.next_modal_path) {
      this.data.set('next-modal-path', detail.next_modal_path)
    }

    if (detail.next_custom_event) {
      this.data.set('next-custom-event', detail.next_custom_event)
    }
  }

  resetForm() {
    $(this.ctaTarget).html('Done')
    $(this.formTarget).find('input').removeAttr('disabled', 'disabled')
    $(this.formTarget).find('button').removeAttr('disabled', 'disabled')
  }
}

