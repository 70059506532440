import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'headline',
    'body',
    'importBody',
    'importHeadline',
    'importColors',
    'importLogo',
    'importBackground',
    'mainTextColor',
    'buttonTextColor',
    'buttonBackgroundColor',
    'backgroundInputContainer',
    'backgroundTemplateImageContainer',
    'logoInputContainer',
    'logoTemplateImageContainer',
    'logoStylesContainer',
    'headerBackground',
    'logoScaleFactor',
    'backgroundOverlayOpacity',
    'preview',
    'previewWrapper',
    'background',
    'logo',
    'previewContainer',
    'desktopPreviewButton',
    'mobilePreviewButton'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }

  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    if (this.data.get('background-blob-path')) {
      this.data.set('background-url', this.data.get('background-blob-path'))
    }

    if (this.data.get('logo-blob-path')) {
      this.data.set('logo-url', this.data.get('logo-blob-path'))
    }

    if (this.hasImportLogoTarget) {
      this.onImportLogoChange()
    }

    this.showMobilePreview()
    this.onImportBackgroundChange()
    this.onImportBodyChange()
    this.onImportHeadlineChange()
  }

  onImportBodyChange() {
    if (this.importBodyTarget.checked) {
      $(this.bodyTarget).attr('disabled', 'disabled')
      $(this.bodyTarget).val(this.data.get('template-body'))
    } else {
      $(this.bodyTarget).removeAttr('disabled')
      $(this.bodyTarget).val(this.data.get('property-body'))
    }

    this.onChange()
  }

  onImportHeadlineChange() {
    if (this.importHeadlineTarget.checked) {
      $(this.headlineTarget).attr('disabled', 'disabled')
      $(this.headlineTarget).val(this.data.get('template-headline'))
    } else {
      $(this.headlineTarget).removeAttr('disabled')
      $(this.headlineTarget).val(this.data.get('property-headline'))
    }

    this.onChange()
  }

  onImportColorsChange() {
    if (this.importColorsTarget.checked) {
      $(this.mainTextColorTarget).attr('disabled', 'disabled')
      $(this.buttonTextColorTarget).attr('disabled', 'disabled')
      $(this.buttonBackgroundColorTarget).attr('disabled', 'disabled')

      $(this.mainTextColorTarget).val(this.data.get('template-main-text-color'))
      $(this.buttonTextColorTarget).val(this.data.get('template-button-text-color'))
      $(this.buttonBackgroundColorTarget).val(this.data.get('template-button-background-color'))

      $(this.mainTextColorTarget).parent('div').addClass('bg-gray-50')
      $(this.buttonTextColorTarget).parent('div').addClass('bg-gray-50')
      $(this.buttonBackgroundColorTarget).parent('div').addClass('bg-gray-50')
    } else {
      $(this.mainTextColorTarget).removeAttr('disabled')
      $(this.buttonTextColorTarget).removeAttr('disabled')
      $(this.buttonBackgroundColorTarget).removeAttr('disabled')

      $(this.mainTextColorTarget).val(this.data.get('property-main-text-color'))
      $(this.buttonTextColorTarget).val(this.data.get('property-button-text-color'))
      $(this.buttonBackgroundColorTarget).val(this.data.get('property-button-background-color'))

      $(this.mainTextColorTarget).parent('div').removeClass('bg-gray-50')
      $(this.buttonTextColorTarget).parent('div').removeClass('bg-gray-50')
      $(this.buttonBackgroundColorTarget).parent('div').removeClass('bg-gray-50')
    }

    this.onChange()
  }

  onImportBackgroundChange() {
    if (this.importBackgroundTarget.checked) {
      $(this.backgroundTemplateImageContainerTarget).show()
      $(this.backgroundInputContainerTarget).hide()

      if (this.hasBackgroundOverlayOpacityTarget) {
        $(this.backgroundOverlayOpacityTarget).val(this.data.get('template-background-overlay-opacity'))
      }
    } else {
      $(this.backgroundTemplateImageContainerTarget).hide()
      $(this.backgroundInputContainerTarget).show()

      if (this.hasBackgroundOverlayOpacityTarget) {
        $(this.backgroundOverlayOpacityTarget).val(this.data.get('property-background-overlay-opacity'))
      }
    }

    this.onChange()
  }

  onImportLogoChange() {
    if (this.importLogoTarget.checked) {
      $(this.logoTemplateImageContainerTarget).show()
      $(this.logoInputContainerTarget).hide()

      $(this.logoScaleFactorTarget).attr('disabled', 'disabled')
      $(this.headerBackgroundTargets).attr('disabled', 'disabled')

      $(this.logoScaleFactorTarget).val(this.data.get('template-logo-scale-factor'))

      this.headerBackgroundTargets.forEach((input) => {
        if (input.value === this.data.get('template-header-background')) {
          input.checked = true
        } else {
          input.checked = false
        }
      })

      if (this.data.get('import-from-template-available') === 'true') {
        $(this.logoStylesContainerTarget).show()
      } else {
        $(this.logoStylesContainerTarget).hide()
      }
    } else {
      $(this.logoTemplateImageContainerTarget).hide()
      $(this.logoInputContainerTarget).show()
      $(this.logoStylesContainerTarget).show()

      $(this.logoScaleFactorTarget).removeAttr('disabled')
      $(this.headerBackgroundTargets).removeAttr('disabled')

      $(this.logoScaleFactorTarget).val(this.data.get('property-logo-scale-factor'))

      this.headerBackgroundTargets.forEach((input) => {
        if (input.value === this.data.get('property-header-background')) {
          input.checked = true
        } else {
          input.checked = false
        }
      })
    }

    this.onChange()
  }

  clearImage() {
    this.data.set('background-url', '')
    this.onChange()
  }

  onLogoChange() {
    const file = this.logoTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('logo-url', file && URL.createObjectURL(file))
    this.onChange()
  }

  onBackgroundChange() {
    const file = this.backgroundTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('background-url', file && URL.createObjectURL(file))
    this.onChange()
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  showDesktopPreview() {
    const width = `${$(this.previewWrapperTarget).width() * 2}px` // multiplying by 2 because the iframe is scaled to 0.5
    $(this.desktopPreviewButtonTarget).removeClass('bg-white').addClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).removeClass('bg-gray-200').addClass('bg-white')
    $(this.previewTargets).css('width', width).addClass('w-full scaled-frame')
  }

  showMobilePreview() {
    $(this.desktopPreviewButtonTarget).addClass('bg-white').removeClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).addClass('bg-gray-200').removeClass('bg-white')
    $(this.previewTargets).css('width', '23rem').removeClass('w-full scaled-frame')
  }

  refreshPreview() {
    if (this.data.get('preview-loaded') === 'true') {
      const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
      $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
    }
  }

  showPreview() {
    $(this.previewContainerTarget).show()
    this.data.set('preview-loaded', true)
    this.debouncedRefreshPreview()
  }

  hidePreview() {
    $(this.previewContainerTarget).hide()
  }

  get previewParams() {
    return {
      headline: this.headlineTarget.value,
      body: this.bodyTarget.value,
      header_background: (this.hasHeaderBackgroundTarget && $(this.headerBackgroundTargets).filter(':checked').val()) || null,
      logo_url: (this.hasImportLogoTarget && !this.importLogoTarget.checked && this.data.get('logo-url')) || undefined,
      logo_scale_factor: this.hasLogoScaleFactorTarget && this.logoScaleFactorTarget.value,
      background_overlay_opacity: this.hasBackgroundOverlayOpacityTarget && this.backgroundOverlayOpacityTarget.value,
      background_url: this.data.get('background-url') || undefined,
      requires_password: this.hasRequiresPasswordTarget && this.requiresPasswordTarget.checked,
      main_text_color: this.hasMainTextColorTarget && this.mainTextColorTarget.value,
      button_background_color: this.hasButtonBackgroundColorTarget && this.buttonBackgroundColorTarget.value,
      button_text_color: this.hasButtonTextColorTarget && this.buttonTextColorTarget.value,
      import_colors_from_template: this.hasImportColorsTarget ? this.importColorsTarget.checked : true,
      import_body_from_template: this.importBodyTarget.checked,
      import_headline_from_template: this.importHeadlineTarget.checked,
      import_logo_from_template: this.hasImportLogoTarget ? this.importLogoTarget.checked : true,
      import_background_from_template: this.importBackgroundTarget.checked,
      rental_property_id: this.data.get('rental-property-id')
    }
  }
}
