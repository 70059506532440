import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'propertiesOnline',
    'apsOnline',
    'guestsOnline',
    'devicesOnline'
  ]

  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get('url'))
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        $(this.propertiesOnlineTarget).text(`${json.properties_online} / ${json.properties_total}`)
        $(this.apsOnlineTarget).text(`${json.aps_online} / ${json.aps_total}`)
        $(this.guestsOnlineTarget).text(json.guests_online)
        $(this.devicesOnlineTarget).text(json.devices_online)
      })
  }
}
