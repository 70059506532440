import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'requiresPassword',
    'password',
    'requiresMarketingConsent',
    'marketingConsentTextContainer',
    'ageRequirement',
    'precheck18PlusContainer'
  ]

  connect() {
    this.togglePasswordInput()
    this.toggleMarketingConsentText()
    this.togglePrecheck18PlusContainer()
  }

  togglePasswordInput() {
    if (this.hasRequiresPasswordTarget && this.hasPasswordTarget) {
      $(this.passwordTarget).toggle(this.requiresPasswordTarget.checked)
    }
  }

  toggleMarketingConsentText() {
    $(this.marketingConsentTextContainerTarget).toggle(this.requiresMarketingConsentTarget.checked)
  }

  togglePrecheck18PlusContainer() {
    if (this.ageRequirementTarget.value === '18_plus') {
      $(this.precheck18PlusContainerTarget).show()
    } else {
      $(this.precheck18PlusContainerTarget).hide()
    }
  }
}
