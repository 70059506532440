import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'enabled',
    'arrowUp',
    'arrowDown',
    'content'
  ]

  connect() {
    this.load()
  }

  load() {
    this.setEnabled()
  }
  
  setEnabled() {
    if (this.enabledTarget.checked || this.enabledTarget.value === 'true') {
      this.contentTarget.style.display = 'block'
      this.arrowUpTarget.style.display = 'block'
      this.arrowDownTarget.style.display = 'none'
    } else {
      this.contentTarget.style.display = 'none'
      this.arrowUpTarget.style.display = 'none'
      this.arrowDownTarget.style.display = 'block'
    }
  }

  toggleEnabled(e) {
    if (this.enabledTarget.checked || this.enabledTarget.value === 'true') {
      this.contentTarget.style.display = 'block'
      this.arrowUpTarget.style.display = 'block'
      this.arrowDownTarget.style.display = 'none'
    } else {
      this.contentTarget.style.display = 'none'
      this.arrowUpTarget.style.display = 'none'
      this.arrowDownTarget.style.display = 'block'
    }
  }

  toggleVisible(e) {
    if ($(this.contentTarget).is(':visible')) {
      this.contentTarget.style.display = 'none'
      this.arrowUpTarget.style.display = 'none'
      this.arrowDownTarget.style.display = 'block'
    } else {
      this.contentTarget.style.display = 'block'
      this.arrowUpTarget.style.display = 'block'
      this.arrowDownTarget.style.display = 'none'
    }
  }
}
