import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'description',
  ]

  connect() {
    this.load();
  }

  load() {
    this.descriptionTarget.addEventListener('input', this.adjustHeight.bind(this));

    this.adjustHeight();
  }

  adjustHeight() {
    this.descriptionTarget.style.height = 'auto';

    if ($(this.descriptionTarget).is(':visible')) {
      this.descriptionTarget.style.height = `${this.descriptionTarget.scrollHeight + 10}px`;
    }
  }
}
