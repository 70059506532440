import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'activityButtonOne',
    'activityButtonTwo',
    'activityOneWrapper',
    'activityTwoWrapper',
    'numberOfActivities',
    'buttonGroup',
    'activityOneTitle',
    'activityOneDescription',
    'activityOneImage',
    'activityOneLink',
    'activityTwoTitle',
    'activityTwoDescription',
    'activityTwoImage',
    'activityTwoLink'
  ]

  connect() {
    this.load()
  }

  load() {
    this.activityOneDescriptionTarget.addEventListener('input', this.adjustDescriptionHeight.bind(this));
    this.activityTwoDescriptionTarget.addEventListener('input', this.adjustDescriptionHeight.bind(this));

    this.adjustDescriptionHeight();
    this.numberOfActivitiesChanged();
    this.selectTabWithErrorsOrFirstTab()
  }

  selectTabWithErrorsOrFirstTab() {
    if (this.activityOneWrapperTarget.querySelector('.is-invalid')) {
      this.activityButtonOneClicked()
    } else if (this.activityTwoWrapperTarget.querySelector('.is-invalid')) {
      this.activityButtonTwoClicked()
    } else {
      this.activityButtonOneClicked()
    }
  }

  numberOfActivitiesChanged(e) {
    if (this.numberOfActivitiesTarget.value.toString() === '1') {
      this.activityButtonOneClicked(e)
      this.buttonGroupTarget.classList.add('hidden')
    } else {
      this.buttonGroupTarget.classList.remove('hidden')
    }
  }

  activityButtonOneClicked(e) {
    if (e) e.preventDefault()

    this.activityButtonOneTarget.classList.add('active-button')
    this.activityButtonTwoTarget.classList.remove('active-button')

    this.activityOneWrapperTarget.classList.remove('hidden')
    this.activityTwoWrapperTarget.classList.add('hidden')

    this.adjustDescriptionHeight()
  }

  activityButtonTwoClicked(e) {
    if(e) e.preventDefault()
    this.activityButtonTwoTarget.classList.add('active-button')
    this.activityButtonOneTarget.classList.remove('active-button')

    this.activityTwoWrapperTarget.classList.remove('hidden')
    this.activityOneWrapperTarget.classList.add('hidden')

    this.adjustDescriptionHeight()
  }

  adjustDescriptionHeight() {
    this.activityOneDescriptionTarget.style.height = 'auto';
    this.activityTwoDescriptionTarget.style.height = 'auto';

    if ($(this.activityOneDescriptionTarget).is(':visible')) {
      this.activityOneDescriptionTarget.style.height = `${this.activityOneDescriptionTarget.scrollHeight + 10}px`;
    }

    if ($(this.activityTwoDescriptionTarget).is(':visible')) {
      this.activityTwoDescriptionTarget.style.height = `${this.activityTwoDescriptionTarget.scrollHeight + 10}px`;
    }
  }
}
