import { Controller } from 'stimulus';
import { Carousel } from '../../vendor/carousel';

export default class extends Controller {
  static targets = ['carousel'];

  connect() {
    const carousel = new Carousel(this.carouselTarget, {
      speed: 1000,
      slideDuration: 5000,
    });

    carousel.init();
  }
}
