import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'container',
    'form',
    'subscriberCount',
    'welcomeMessageSent',
    'reviewMessageSent',
    'welcomeCampaignMessageDelivered',
    'reviewCampaignMessageDelivered',
    'welcomeCampaignLinksClicked',
    'reviewCampaignLinksClicked',
    'welcomeUnsubscribes',
    'reviewUnsubscribes'
  ]

  connect() {
    this.submitForm()
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit')
  }

  onSubmit(e) {
    const response = e.detail[0]
    const json = JSON.parse(response.responseText)

    if (this.hasSubscriberCountTarget) {
      $(this.subscriberCountTarget).text(this.formatNumber(json.subscribers_count))
    }

    if (this.hasWelcomeMessageSentTarget) {
      $(this.welcomeMessageSentTarget).text(this.formatNumber(json.welcome_campaign_message_send_count))
    }

    if (this.hasReviewMessageSentTarget) {
      $(this.reviewMessageSentTarget).text(this.formatNumber(json.review_campaign_message_send_count))
    }

    if (this.hasWelcomeCampaignMessageDeliveredTarget) {
      $(this.welcomeCampaignMessageDeliveredTarget).text(this.formatNumber(json.welcome_campaign_message_delivered_count))
    }

    if (this.hasReviewCampaignMessageDeliveredTarget) {
      $(this.reviewCampaignMessageDeliveredTarget).text(this.formatNumber(json.review_campaign_message_delivered_count))
    }

    if (this.hasWelcomeCampaignLinksClickedTarget) {
      $(this.welcomeCampaignLinksClickedTarget).text(this.formatNumber(json.welcome_campaign_links_clicked_count))
    }

    if (this.hasReviewCampaignLinksClickedTarget) {
      $(this.reviewCampaignLinksClickedTarget).text(this.formatNumber(json.review_campaign_links_clicked_count))
    }

    if (this.hasWelcomeUnsubscribesTarget) {
      $(this.welcomeUnsubscribesTarget).text(this.formatNumber(json.welcome_unsubscribes_count))
    }

    if (this.hasReviewUnsubscribesTarget) {
      $(this.reviewUnsubscribesTarget).text(this.formatNumber(json.review_unsubscribes_count))
    }
  }

  formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}
