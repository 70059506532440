import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tableContainer', 'action']

  toggle() {
    const rawDuration = this.data.get('animation-duration')
    // if duration wasn't passed to controller, pass undefined to slideToggle to use jQuery's default duration value
    const duration = rawDuration ? parseInt(rawDuration) : undefined

    $(this.tableContainerTargets).slideToggle(duration)
    $(this.actionTarget).toggleClass('-rotate-90')
  }
}
