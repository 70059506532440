import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'propertyGroupInput',
    'newPropertyGroupContainer',
    'splashPageSelectContainer',
    'occupancyAlertsDynamicInput',
    'occupancyAlertsThresholdLabel',
    'occupancyAlertsThresholdHint',
    'qrCodeContainer'
  ]

  connect() {
    if (this.hasOccupancyAlertsDynamicInputTarget) {
      this.onOccupancyAlertsDynamicChange()
    }

    if (this.hasPropertyGroupInputTarget) {
      this.updatePropertyGroup()
    }
  }

  updatePropertyGroup() {
    const value = $(this.propertyGroupInputTarget).val()

    if (value === 'add') {
      $(this.newPropertyGroupContainerTarget).show()
    } else {
      $(this.newPropertyGroupContainerTarget).hide()
    }
  }

  onOccupancyAlertsDynamicChange() {
    if ($(this.occupancyAlertsDynamicInputTarget).is(':checked')) {
      $(this.occupancyAlertsThresholdLabelTarget).text('Fallback Threshold')
      $(this.occupancyAlertsThresholdHintTarget).text('This fallback threshold will be used if we cannot find a current reservation for the property')
    } else {
      $(this.occupancyAlertsThresholdLabelTarget).text('Occupancy Threshold')
      $(this.occupancyAlertsThresholdHintTarget).text('')
    }
  }

  toggleQRCodeContainer() {
    $(this.qrCodeContainerTarget).slideToggle()
  }
}
