import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'formFieldToDisable',
    'importContactInfo',
    'contactPhone',
    'contactEmail',
    'whatsappUrl',
    'importImage',
    'imageContainer',
    'uploadImageContainer',
    'preview',
    'previewWrapper',
    'showAddress',
    'image',
    'guidebookUrl',
    'guidebookFile',
    'guidebookHiddenFile',
    'listingUrl',
    'guidebookUrlEnabled',
    'listingUrlEnabled',
    'companyWebsiteEnabled',
    'companyWebsiteUrl',
    'importWebsiteUrl',
    'customButtonContainer',
    'previewContainer',
    'desktopPreviewButton',
    'mobilePreviewButton',
    'viatorEnabled',
    'viator',
    'hostco',
    'hostcoEnabled'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }

  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    if (this.data.get('image-blob-path')) {
      this.data.set('image-url', this.data.get('image-blob-path'))
    }

    if (this.hasContactPhoneTarget && this.hasContactEmailTarget) {
      this.onImportContactInfoChange()
    }

    this.onImportImageChange()

    this.showMobilePreview()
    this.refreshPreview()
  }

  onImportContactInfoChange() {
    if (this.importContactInfoTarget.checked) {
      $(this.formFieldToDisableTargets).attr('disabled', 'disabled')
      $(this.contactPhoneTarget).val(this.data.get('template-contact-phone'))
      $(this.contactEmailTarget).val(this.data.get('template-contact-email'))
      $(this.whatsappUrlTarget).val(this.data.get('template-whatsapp-url'))
    } else {
      $(this.formFieldToDisableTargets).removeAttr('disabled')
      $(this.contactPhoneTarget).val(this.data.get('property-contact-phone'))
      $(this.contactEmailTarget).val(this.data.get('property-contact-email'))
      $(this.whatsappUrlTarget).val(this.data.get('property-whatsapp-url'))
    }
  }

  onImportImageChange() {
    if (this.hasImportImageTarget && this.importImageTarget.checked) {
      if (this.hasImageContainerTarget) {
        $(this.imageContainerTarget).show()
      }

      if (this.hasImageContainerTarget) {
        $(this.uploadImageContainerTarget).hide()
      }
    } else {
      if (this.hasImageContainerTarget) {
        $(this.imageContainerTarget).hide()
      }

      if (this.hasImageContainerTarget) {
        $(this.uploadImageContainerTarget).show()
      }
    }
  }

  onImageChange() {
    const file = this.imageTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('image-url', file && URL.createObjectURL(file))
    this.onChange()
  }

  clearImage() {
    this.data.set('image-url', '')
    this.onChange()
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  showDesktopPreview() {
    const width = `${$(this.previewWrapperTarget).width() * 2}px` // multiplying by 2 because the iframe is scaled to 0.5
    $(this.desktopPreviewButtonTarget).removeClass('bg-white').addClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).removeClass('bg-gray-200').addClass('bg-white')
    $(this.previewTargets).css('width', width).addClass('w-full scaled-frame')
  }

  showMobilePreview() {
    $(this.desktopPreviewButtonTarget).addClass('bg-white').removeClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).addClass('bg-gray-200').removeClass('bg-white')
    $(this.previewTargets).css('width', '23rem').removeClass('w-full scaled-frame')
  }

  refreshPreview() {
    if (this.data.get('preview-loaded') === 'true') {
      const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
      $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
    }
  }

  showPreview() {
    $(this.previewContainerTarget).show()
    this.data.set('preview-loaded', true)
    this.debouncedRefreshPreview()
  }

  hidePreview() {
    $(this.previewContainerTarget).hide()
  }

  get previewParams() {
    const customButtonParams = this.customButtonContainerTargets.map((button) => {
      const $button = $(button)

      return {
        template_tile_id: button.dataset.templateTileId,
        active: $button.find('input.custom-button-enabled').length ? $button.find('input.custom-button-enabled').val() : true,
        url: $button.find('input.custom-button-url').val(),
        uploaded_file: !!$button.find('input.custom-button-file').val() || !!$button.find('input.custom-button-hidden-file').val()
      }
    })

    return {
      rental_property_id: this.data.get('rental-property-id'),
      contact_email: (this.hasContactEmailTarget && this.contactEmailTarget.value) || null,
      contact_phone: (this.hasContactPhoneTarget && this.contactPhoneTarget.value) || null,
      whatsapp_url: (this.hasWhatsappUrlTarget && this.whatsappUrlTarget.value) || null,
      import_contact_from_template: this.hasImportContactInfoTarget ? this.importContactInfoTarget.checked : true,
      import_image_from_pms: this.hasImportImageTarget && this.importImageTarget.checked,
      image_url: this.data.get('image-url'),
      show_property_address: this.hasShowAddressTarget && this.showAddressTarget.checked,
      listing_url: this.hasListingUrlTarget && this.listingUrlTarget.value,
      guidebook_url: this.hasGuidebookUrlTarget && this.guidebookUrlTarget.value,
      guidebook_url_enabled: this.hasGuidebookUrlEnabledTarget ? this.guidebookUrlEnabledTarget.value === 'true' : true,
      guidebook_uploaded: (this.hasGuidebookFileTarget && !!this.guidebookFileTarget.value) || (this.hasGuidebookHiddenFileTarget && !!this.guidebookHiddenFileTarget.value),
      listing_url_enabled: this.hasListingUrlEnabledTarget ? this.listingUrlEnabledTarget.value === 'true' : true,
      company_website_enabled: this.hasCompanyWebsiteEnabledTarget && this.companyWebsiteEnabledTarget.value === 'true',
      company_website_url: this.hasCompanyWebsiteUrlTarget && this.companyWebsiteUrlTarget.value,
      import_company_website_url_from_template: this.hasImportWebsiteUrlTarget && this.importWebsiteUrlTarget.value === 'true',
      custom_buttons: customButtonParams,
      viator: this.hasViatorTarget && this.viatorTarget.value,
      viator_enabled: this.hasViatorEnabledTarget ? this.viatorEnabledTarget.value === 'true' : false,
      hostco: this.hasHostcoTarget && this.hostcoTarget.value,
      hostco_enabled: this.hasHostcoEnabledTarget ? this.hostcoEnabledTarget.value === 'true' : false
    }
  }
}
