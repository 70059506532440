import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['errorBanner', 'errorMessage', 'continueButton', 'providerFetchCheck', 'domainInput']

  connect() {
    this.startPolling()
    this.setupCompleted = false
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 1500)
  }

  poll() {
    const url = this.data.get('url')
    const redirectionUrl = this.data.get('redirection-url');

    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (!this.setupCompleted && json.setup_completed && json.email_marketing_account_dns_verification_ready) {
          this.setupCompleted = true
          clearInterval(this.interval)

          window.location.href = redirectionUrl;
        } else {
          this.handleSetupNotCompleted(json.domain_registration_error_message)
        }
      })
  }

  showErrorMessage(message) {
    if (!this.hasDomainInputTarget || !this.hasErrorMessageTarget)
      return

    this.domainInputTarget.style.borderColor = '#EF4444';
    this.errorMessageTarget.style.display = 'block'
    this.errorMessageTarget.innerText = message
  }

  hideErrorMessage() {
    if (!this.hasDomainInputTarget || !this.hasErrorMessageTarget)
      return

    this.errorMessageTarget.innerText = ''
  }

  handleSetupNotCompleted(errorMessage) {
    if (errorMessage) {
      this.showErrorMessage(errorMessage)

      this.continueButtonTarget.disabled = true
      this.continueButtonTarget.innerHTML = '<div id="dns-provider-selection-continue" class="flex justify-center"><span data-target="dns-provider-selection.buttonText">Continue</span></div>'
    } else {
      this.hideErrorMessage()

      if (this.continueButtonTarget.innerHTML.includes('spinner')) {
        this.continueButtonTarget.disabled = true
      } else {
        this.continueButtonTarget.disabled = false
      }
    }
  }
}
