import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'container',
    'spinner'
  ]

  initialize() {
    this.page = 1
  }

  connect() {
    this.load(this.page)
  }

  load(page) {
    $(this.spinnerTarget).show()

    fetch(`${this.data.get('url')}?page=${page}`)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        this.page = this.page + 1

        $(this.containerTarget).append(json.table_rows_html)
        $(this.spinnerTarget).hide()

        if (json.more_results) {
          this.load(this.page)
        }
      })
  }
}
