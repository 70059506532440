import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'input',
  ]

  onMergeFieldClick(e) {
    const { name } = e.target.dataset
    const message = $(this.inputTarget).val()

    $(this.inputTarget).val(`${message} ${name}`)
  }
}
