import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'sendCta', 'editCta', 'input'
  ]

  connect() {
    if (this.data.get('enabled-at-init') === 'true') {
      this.enableEdit()
    } else {
      this.disableEdit()
    }
  }

  disableEdit() {
    $(this.editCtaTarget).show()
    $(this.sendCtaTarget).hide()
    $(this.inputTarget).attr('readonly', 'readonly').addClass('readonly')
  }

  enableEdit() {
    $(this.editCtaTarget).hide()
    $(this.sendCtaTarget).show()
    $(this.inputTarget).removeAttr('readonly').removeClass('readonly')
  }
}
