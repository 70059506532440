import { Controller } from 'stimulus'
import { Confetti } from '../../utilities/confetti'

export default class extends Controller {
  static targets = ['container', 'confetti']

  connect() {
    this.poll()
    this.startPolling()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 2000)
  }

  poll() {
    const url = this.data.get('url')
    
    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        $(this.containerTarget).html(json.text_message_campaign_status)
        if (json.finished) {
          clearInterval(this.interval)
          this.showConfetti()
        }
      })
  }

  showConfetti() {
    const confetti = new Confetti(this.confettiTarget)
    confetti.animate()
  }
}
