import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'imageInput',
    'imageInputHidden',
    'imageThumbnail',
    'thumbnailIcon',
    'thumbnailButton',
    'imagesContainer',
    'destinationImageInput',
    'existingImage',
    'selectedExistingImage',
    'selectedNewImageIndex',
    'formSubmitBtn',
  ]

  connect() {
  }

  selectExistingImage(e) {
    e.preventDefault();
    const blobId = e.target.dataset.blobId;
    this.selectedExistingImageTarget.value = blobId;

    this.selectImage(e.target, blobId, null)
  }

  onImageChange() {
    const file = this.imageInputTarget.files[0]
    if (!file) {
      return
    }

    const imageUrl = URL.createObjectURL(file)
    this.appendUploadedImageThumbnail(imageUrl, file)

    // We want to support the use case when customer clicks multiple times
    // on Upload image and selects multiple images
    // This means that we needed a mechanism to keep images in a buffer until the form is submitted.
    // For these, we used a second file field that is hidden and we append the selected images to it.
    // To transfer the images from the hidden field to the actual field we use DataTransfer
    const dataTransfer = new DataTransfer();
    for (let i = 0; i < this.destinationImageInputTarget.files.length; i++) {
      dataTransfer.items.add(this.destinationImageInputTarget.files[i]);
    }
    dataTransfer.items.add(file);

    this.destinationImageInputTarget.files = dataTransfer.files
    this.imageInputTarget.value = '';

    this.setConsecutiveIndexToUploadedImages()
  }

  setConsecutiveIndexToUploadedImages() {
    // The images are displayed in reverse order because the images are ordered in reverse chronological order by creation date
    const images = this.imagesContainerTarget.querySelectorAll('.new-gallery-image');
    const totalImages = images.length;
    images.forEach((image, index) => {
      image.dataset.index = totalImages - 1 - index;
    });
  }

  // There are 2 types of images: existing images which were uploaded in the past
  // and newly uploaded images. If the image already exists, we pass the blob ID,
  // but if the image is newly created we pass the index of that image to be able to recognize
  // on the server which image was selected
  selectImage(target, selectedExistingImageBlobId, selectedNewImageIndex) {
    // If it's an existing image, set the blobID, otherwise set the index of the newly uploaded image
    // to be able to know on the server which image was selected
    if (selectedExistingImageBlobId) {
      this.selectedExistingImageTarget.value = selectedExistingImageBlobId
      this.selectedNewImageIndexTarget.value = null
    } else {
      this.selectedNewImageIndexTarget.value = selectedNewImageIndex
      this.selectedExistingImageTarget.value = null
    }

    // Remove the border from all images
    const parentElement = target.parentElement;
    this.imagesContainerTarget.querySelectorAll('.new-gallery-image').forEach(image => {
      image.parentElement.classList.remove('border-2', 'border-blue-600');
    });
    this.existingImageTargets.forEach(target => {
      target.parentElement.classList.remove('border-2', 'border-blue-600');
    });

    // Add border to the selected image
    parentElement.classList.add('border-2', 'border-blue-600');

    // Enable the submit button since now we have an image selected
    this.formSubmitBtnTarget.removeAttribute('disabled');
  }
  
  appendUploadedImageThumbnail(imageUrl, file) {
    const imageName = file.name

    // Create thumbnail
    const thumb = document.createElement('img')
    thumb.src = imageUrl
    thumb.className = 'new-gallery-image w-full h-full object-cover'
    thumb.dataset.fileName = imageName

    // Event for selecting thumbnail
    thumb.addEventListener('click', (event) => {
      event.stopPropagation()
      event.preventDefault()

      this.selectImage(thumb, null, thumb.dataset.index)
    })

    // Create thumbnail container
    const thumbnailContainer = document.createElement('div')
    thumbnailContainer.className = 'h-20 bg-gray-200 relative items-center justify-center overflow-hidden rounded-xl'
    thumbnailContainer.appendChild(thumb)

    // Create remove button
    const removeButton = document.createElement('button')
    removeButton.className = 'absolute top-1 right-1 p-1 rounded-full bg-blue-800'
    removeButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" aria-hidden="true" class="w-auto text-black" style="height: 13px;">\n' +
      '  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>\n' +
      '</svg>'

    // Remove button click event
    removeButton.addEventListener('click', (event) => {
      event.stopPropagation()
      event.preventDefault()

      this.removeUploadedImage(thumbnailContainer, file)
    })

    // Add thumbnail and container to the images gallery
    thumbnailContainer.appendChild(removeButton)
    if (this.imagesContainerTarget.children.length === 0) {
      this.imagesContainerTarget.appendChild(thumbnailContainer)
    } else {
      this.imagesContainerTarget.insertBefore(thumbnailContainer, this.imagesContainerTarget.children[1])
    }

    // Select the newly uploaded image
    const thumbIndex = this.imagesContainerTarget.querySelectorAll('.new-gallery-image').length - 1
    this.selectImage(thumb, null, thumbIndex)
  }

  // Removes the thumbnail from the gallery and the image from the hidden input.
  // We can only delete the newly uploaded images. Existing images can't be deleted.
  removeUploadedImage(thumbnailContainer, file) {
    thumbnailContainer.remove()

    const dataTransfer = new DataTransfer();
    for (let i = 0; i < this.destinationImageInputTarget.files.length; i++) {
      if (this.destinationImageInputTarget.files[i] !== file) {
        dataTransfer.items.add(this.destinationImageInputTarget.files[i]);
      }
    }
    this.destinationImageInputTarget.files = dataTransfer.files

    this.setConsecutiveIndexToUploadedImages()
  }
}
