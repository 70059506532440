import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'configFields' ]

  changeConfigFields(e) {
    const provider = $(e.currentTarget).val()
    if (provider) {
      $.get(this.data.get('url'), {
        provider
      }).done(data => {
        this.configFieldsTarget.innerHTML = data
      })
    } else {
      this.configFieldsTarget.innerHTML = ''
    }
  }
}
