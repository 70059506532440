import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'preview',
    'previewWrapper',
    'headerBackground',
    'headline',
    'body',
    'requiresPassword',
    'requiresPhoneNumber',
    'ageRequirement',
    'ageRequirementEnabled',
    'precheck18Plus',
    'precheck18PlusContainer',
    'requiresMarketingConsent',
    'marketingConsentTextContainer',
    'marketingConsentText',
    'precheckMarketingConsent',
    'password',
    'form',
    'desktopPreviewButton',
    'mobilePreviewButton',
    'logoScaleFactor',
    'headerEnabled',
    'backgroundOverlayOpacity',
    'mainTextColorInput',
    'buttonBackgroundColorInput',
    'buttonTextColorInput',
    'propertyNameFallback',
    'propertyGroupFallback',
    'propertyNameFallbackInput',
    'propertyGroupFallbackInput',
    'background',
    'desktopPreviewButton',
    'mobilePreviewButton',
    'collectPhonesForSMSMarketing'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }
  
  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    if (this.data.get('background-blob-path')) {
      this.data.set('background-url', this.data.get('background-blob-path'))
    }

    this.showMobilePreview()
    this.onContentChange()
    this.togglePrecheck18PlusContainer()
  }

  onHeadlineMergeFieldClick(e) {
    const { name } = e.target.dataset
    const headline = $(this.headlineTarget).val()

    $(this.headlineTarget).val(`${headline}${name}`)
    this.onContentChange()
  }

  onBodyMergeFieldClick(e) {
    const { name } = e.target.dataset
    const body = $(this.bodyTarget).val()

    $(this.bodyTarget).val(`${body}${name}`)
    this.onContentChange()
  }

  onContentChange() {
    const headline = $(this.headlineTarget).val()
    const body = $(this.bodyTarget).val()
    const mergeFields = JSON.parse(this.data.get('merge-fields'))

    mergeFields.forEach(mergeField => {
      if (headline.includes(mergeField.name) || body.includes(mergeField.name)) {
        $(this[`${mergeField.fallback_field_name}FallbackTarget`]).show()
      } else {
        $(this[`${mergeField.fallback_field_name}FallbackTarget`]).hide()
      }
    })

    this.refreshPreview()
  }

  togglePrecheck18PlusContainer() {
    if (this.ageRequirementTarget.value === '18_plus') {
      $(this.precheck18PlusContainerTarget).show()
    } else {
      $(this.precheck18PlusContainerTarget).hide()
    }

    this.refreshPreview()
  }

  onBackgroundChange() {
    const file = this.backgroundTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('background-url', file && URL.createObjectURL(file))
    this.onChange()
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  showDesktopPreview() {
    const width = `${$(this.previewWrapperTarget).width() * 2}px` // multiplying by 2 because the iframe is scaled to 0.5
    $(this.desktopPreviewButtonTarget).removeClass('bg-white').addClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).removeClass('bg-gray-200').addClass('bg-white')
    $(this.previewTargets).css('width', width).addClass('w-full scaled-frame')
  }

  showMobilePreview() {
    $(this.desktopPreviewButtonTarget).addClass('bg-white').removeClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).addClass('bg-gray-200').removeClass('bg-white')
    $(this.previewTargets).css('width', '23rem').removeClass('w-full scaled-frame')
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
  }

  get previewParams() {
    return {
      headline: this.headlineTarget.value,
      body: this.bodyTarget.value,
      header_enabled: this.hasHeaderEnabledTarget && this.headerEnabledTarget.checked,
      header_background: (this.hasHeaderBackgroundTarget && $(this.headerBackgroundTargets).filter(':checked').val()) || null,
      logo_url: this.data.get('logo-url') || undefined,
      logo_scale_factor: this.hasLogoScaleFactorTarget && this.logoScaleFactorTarget.value,
      background_overlay_opacity: this.backgroundOverlayOpacityTarget.value,
      background_url: this.data.get('background-url') || undefined,
      requires_password: this.hasRequiresPasswordTarget && this.requiresPasswordTarget.checked,
      requires_phone_number: this.hasRequiresPhoneNumberTarget && this.requiresPhoneNumberTarget.checked,
      age_requirement: (this.ageRequirementEnabledTarget.checked && this.ageRequirementTarget.value) || null,
      precheck_18_plus: this.precheck18PlusTarget.checked,
      requires_marketing_consent: this.requiresMarketingConsentTarget.checked,
      marketing_consent_text: this.marketingConsentTextTarget.value,
      precheck_marketing_consent: this.precheckMarketingConsentTarget.checked,
      main_text_color: this.mainTextColorInputTarget.value,
      button_background_color: this.buttonBackgroundColorInputTarget.value,
      button_text_color: this.buttonTextColorInputTarget.value,
      property_name_fallback: this.hasPropertyNameFallbackInputTarget && this.propertyNameFallbackInputTarget.value,
      property_group_fallback: this.hasPropertyGroupFallbackInputTarget && this.propertyGroupFallbackInputTarget.value,
      collect_sms_subscribers: this.hasRequiresPhoneNumberTarget && this.requiresPhoneNumberTarget.checked && this.hasCollectPhonesForSMSMarketingTarget && this.collectPhonesForSMSMarketingTarget.checked
    }
  }
}
