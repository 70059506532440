import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ 'menuContainer','menuItemContainer', 'menu', 'menuItem', 'menuSearchField', 'menuSearchForm' ]

  initialize() {
    this.debouncedSubmitForm = _.debounce(() => {
      this.submitForm()
    }, 200)
  }

  toggleMenu(e) {
    e.stopImmediatePropagation()

    if ($(this.menuContainerTarget).is(':visible')) {
      this.animateHideMenu()
    } else {
      $(this.menuContainerTarget).show()
      $(this.menuTarget).addClass('ease-out opacity-100 scale-100').removeClass('ease-in opacity-0 scale-95')
    }
  }

  hideMenu(e) {
    if($(event.target).closest($(this.menuTarget)).length === 0 && $(this.menuContainerTarget).is(':visible')) {
      this.animateHideMenu()
    }
  }

  animateHideMenu() {
    $(this.menuTarget).addClass('ease-in opacity-0 scale-95').removeClass('ease-out opacity-100 scale-100').one('transitionend', (event) => {
      $(this.menuContainerTarget).hide()
    })
  }

  onSearchTermChange(e) {
    const value = this.menuSearchFieldTarget.value

    if (value.length > 1) {
      this.debouncedSubmitForm()
    }

    if (value.length === 0) {
      if ($(this.menuSearchFieldTarget).data('show-full-list')) {
        this.debouncedSubmitForm()
      } else {
        this.clearEntries()
      }
    }
  }

  submitForm() {
    Rails.fire(this.menuSearchFormTarget, 'submit')
  }

  clearEntries() {
    $(this.menuItemContainerTarget).empty()
  }

  debounceVisible() {
    if ($(this.menuContainerTarget).is(':visible')) {
      this.debouncedSubmitForm()
    }
  }

  handleSearchResults(e) {
    const [data] = e.detail
    this.clearEntries()
    $(this.menuItemContainerTarget).append(data.body.innerHTML)
  }
}
