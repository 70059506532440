import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'accessPointName',
    'accessPointModel',
    'accessPointUptime'
  ]

  connect() {
    this.fetchAccessPointStatuses()
    this.fetchHistory(0)
  }

  fetchAccessPointStatuses() {
    fetch(this.data.get('live-access-point-basic-status-url'))
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        json.access_point_statuses.forEach((status) => {
          $(this.accessPointNameTargets).filter(`[data-mac-address="${status.mac_address}"]`).html(status.name)
          $(this.accessPointModelTargets).filter(`[data-mac-address="${status.mac_address}"]`).html(status.model_name)
        })
      })
  }

  fetchHistory(page) {
    const url = this.rentalPropertyGroupHistoryUrls[page]

    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        json.access_point_history_samples.forEach((sample) => {
          $(this.accessPointUptimeTargets).filter(`[data-mac-address="${sample.mac_address}"]`).html(sample.uptime_html)
        })

        if (this.rentalPropertyGroupHistoryUrls[page + 1]) {
          this.fetchHistory(page + 1)
        }
      })
  }


  get rentalPropertyGroupHistoryUrls() {
    return JSON.parse(this.data.get('rental-property-group-uptime-history-urls'))
  }
}
