import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'mergeField',
    'subject'
  ]

  connect() {
    this.refreshMergeFieldsButtons();
  }

  insert(e) {
    const mergeField = e.currentTarget.textContent.trim();
    const caretPosition = this.subjectTarget.selectionStart;
    const currentValue = this.subjectTarget.value;
    this.subjectTarget.value = currentValue.slice(0, caretPosition) + mergeField + currentValue.slice(caretPosition);
    this.subjectTarget.dispatchEvent(new Event('input'));
  }

  subjectChange(e) {
    this.refreshMergeFieldsButtons();
  }

  refreshMergeFieldsButtons() {
    this.mergeFieldTargets.forEach((button) => {
      if (this.subjectTarget.value.includes(button.textContent.trim())) {
        this.selectMergeFieldButton(button);
      } else {
        this.unselectMergeFieldButton(button);
      }
    });
  }

  selectMergeFieldButton(button) {
    button.classList.remove('email-marketing-merge-field-button');
    button.classList.add('email-marketing-merge-field-button-selected');
  }

  unselectMergeFieldButton(button) {
    button.classList.remove('email-marketing-merge-field-button-selected');
    button.classList.add('email-marketing-merge-field-button');
  }

}
