import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'accountId',
    'listIdContainer',
    'submit'
  ]

  connect() {
    this.changeListIdField()
  }

  changeListIdField() {
    this.submitTarget.setAttribute('disabled', 'disabled')
    const accountId = $(this.accountIdTarget).val()
    if (accountId) {
      $.get(this.data.get('url'), {
        account_id: accountId
      }).done(data => {
        this.listIdContainerTarget.innerHTML = data
        this.submitTarget.removeAttribute('disabled')
      })
    } else {
      this.listIdContainerTarget.innerHTML = ''
    }
  }
}
