import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'iframe',
    'spinner',
    'errorMessage'
  ]

  handleLoad() {
    $(this.spinnerTarget).addClass('hidden')

    if (this.data.get('isSafari') === 'true') {
      $(this.errorMessageTarget).removeClass('hidden')
    }
  }

  connect() {
    this.iframeTarget.addEventListener('load', this.handleLoad.bind(this));
  }

  disconnect() {
    this.iframeTarget.removeEventListener('load', this.handleLoad.bind(this))
  }
}
