import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'preview',
    'headline',
    'body',
    'desktopPreviewButton',
    'mobilePreviewButton',
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }

  connect() {
    this.showMobilePreview()
    this.refreshPreview()
  }

  showMobilePreview() {
    this.addActiveStyle(this.mobilePreviewButtonTarget)
    this.removeActiveStyle(this.desktopPreviewButtonTarget)

    $(this.previewTarget).attr('height', '510').removeClass().addClass('mx-auto relative')
  }

  showDesktopPreview() {
    this.addActiveStyle(this.desktopPreviewButtonTarget)
    this.removeActiveStyle(this.mobilePreviewButtonTarget)

    $(this.previewTarget).attr('height', '800').removeClass().addClass('relative w-full zoom-50')
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`

    $(this.previewTarget).attr('src', src)
  }

  addActiveStyle(element) {
    $(element).addClass('bg-blue-600 text-white').removeClass('bg-white')
  }

  removeActiveStyle(element) {
    $(element).addClass('bg-white').removeClass('bg-blue-600 text-white')
  }

  get previewParams() {
    return {
      heading: this.headlineTarget.value,
      body: this.bodyTarget.value
    }
  }
}
