import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    this.startPolling()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 1500)
  }

  poll() {
    const url = this.data.get('import-status-url')

    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (json.finished) {
          document.dispatchEvent(new CustomEvent('finished_importing_pms_properties'))
          clearInterval(this.interval)
        }
      })
  }
}
