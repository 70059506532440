import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { each } from 'lodash'
import { eventTracker } from '../../utilities/customer'

export default class extends Controller {
  static targets = [
    'form',
    'messageForm',
    'container',
    'customized',
    'active',
    'submitCta',
    'message',
    'preview',
    'copyContent'
  ]

  connect() {
    if (this.hasPreviewTarget) {
      this.updatePreview()
    }
  }

  onCopyClick() {
    eventTracker.track('copy_icon.clicked', {
      flow: 'pre_arrival_messaging_manual',
      object: 'copy_icon',
      action: 'clicked',
      property: this.data.get('property-id')
    })
  }

  onEditClick() {
    $(this.containerTarget).show()
  }

  onCancelClick() {
    $(this.containerTarget).hide()
  }

  onSaveClick() {
    $(this.customizedTarget).val(true)

    Rails.fire(this.messageFormTarget, 'submit')
  }

  onResetClick() {
    $(this.customizedTarget).val(false)

    Rails.fire(this.messageFormTarget, 'submit')
  }

  onActiveClick() {
    Rails.fire(this.formTarget, 'submit')
  }

  onMessageChange() {
    $(this.submitCtaTarget).removeAttr('disabled')
    this.updatePreview()
  }

  onMergeFieldClick(e) {
    const { name } = e.target.dataset
    const message = $(this.messageTarget).val()

    $(this.messageTarget).val(`${message} ${name}`)
    this.onMessageChange()
  }

  onMessageTemplateChange(e) {
    if (this.hasPreviewTarget) {
      const { message } = e.detail

      $(this.messageTarget).val(message)
      this.updatePreview()
    }
  }

  updatePreview() {
    const { mergeFields } = this.messageTarget.dataset
    const formattedMessage = this.formatMessage(mergeFields, $(this.messageTarget).val())
    const previewMessage = formattedMessage.replace(/\n/g, '<br>')

    $(this.previewTarget).html(previewMessage).scrollTop(this.previewTarget.scrollHeight)

    if (this.hasCopyContentTarget) {
      $(this.copyContentTargets).attr('data-copy-to-clipboard-text', formattedMessage)
    }
  }

  formatMessage(mergeFields, message) {
    each(JSON.parse(mergeFields), (value, key) => {
      message = message.replace(new RegExp(`%%${key}%%`, 'g'), value)
    })

    return message
  }
}
