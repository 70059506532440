import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'firstNameMergeField',
    'lastNameMergeField',
    'propertyNameMergeField',
    'propertyGroupMergeField',
    'introductionText'
  ]

  connect() {
    this.load();
  }

  load() {
    this.introductionTextTarget.addEventListener('input', this.adjustHeight.bind(this));
    this.introductionTextTarget.addEventListener('input', this.refreshMergeFieldsButtons.bind(this));

    this.adjustHeight();
    this.refreshMergeFieldsButtons();
  }

  click(e) {
    e.preventDefault()

    this.insertMergeField(e.target)
    this.introductionTextTarget.dispatchEvent(new Event('input'));
    this.adjustHeight();
    this.refreshMergeFieldsButtons();
  }

  insertMergeField(mergeFieldButton) {
    const textToInsert = mergeFieldButton.textContent.trim();
    const caretPosition = this.introductionTextTarget.selectionStart;
    const currentValue = this.introductionTextTarget.value;

    this.introductionTextTarget.value = currentValue.slice(0, caretPosition) + textToInsert + currentValue.slice(caretPosition);
    this.introductionTextTarget.setSelectionRange(caretPosition + textToInsert.length, caretPosition + textToInsert.length);
  }

  refreshMergeFieldsButtons() {
    if (this.introductionTextTarget.value.includes('%%FIRSTNAME%%')) {
      this.selectMergeFieldButton(this.firstNameMergeFieldTarget);
    } else {
      this.unselectMergeFieldButton(this.firstNameMergeFieldTarget);
    }

    if (this.introductionTextTarget.value.includes('%%LASTNAME%%')) {
      this.selectMergeFieldButton(this.lastNameMergeFieldTarget);
    } else {
      this.unselectMergeFieldButton(this.lastNameMergeFieldTarget);
    }

    if (this.hasPropertyNameMergeFieldTarget) {
      if (this.introductionTextTarget.value.includes('%%PROPERTYNAME%%')) {
        this.selectMergeFieldButton(this.propertyNameMergeFieldTarget);
      } else {
        this.unselectMergeFieldButton(this.propertyNameMergeFieldTarget);
      }
    }

    if (this.hasPropertyGroupMergeFieldTarget) {
      if (this.introductionTextTarget.value.includes('%%PROPERTYGROUP%%')) {
        this.selectMergeFieldButton(this.propertyGroupMergeFieldTarget);
      } else {
        this.unselectMergeFieldButton(this.propertyGroupMergeFieldTarget);
      }
    }
  }

  selectMergeFieldButton(button) {
    button.classList.remove('email-marketing-merge-field-button');
    button.classList.add('email-marketing-merge-field-button-selected');
  }

  unselectMergeFieldButton(button) {
    button.classList.remove('email-marketing-merge-field-button-selected');
    button.classList.add('email-marketing-merge-field-button');
  }

  adjustHeight() {
    this.introductionTextTarget.style.height = 'auto';

    if ($(this.introductionTextTarget).is(':visible')) {
      this.introductionTextTarget.style.height = `${this.introductionTextTarget.scrollHeight + 10}px`;
    }
  }
}
