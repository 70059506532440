import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'

export default class extends Controller {
  static targets = [
    'imageInput',
    'imageInputHidden',
    'imageThumbnail',
    'thumbnailIcon',
    'thumbnailButton',
    'message',
    'spinner'
  ]

  maxFileByteSize = 10000000
  allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg', 'image/svg+xml', 'image/webp', 'image/gif']

  connect() {
    this.setThumbnail()
  }

  onImageChange() {
    // remove the hidden input because we don't need it anymore
    // it's only there for when we reload the page after an error
    // to ensure the image gets resubmitted if the user doesn't change anything
    // but if they do change the file after getting an error, without this,
    // the original file would have been submitted instead of the new one
    // because this hidden field (which has the signed id of the original file)
    // would have taken precedence over the hidden field that the
    // ActiveStorage DirectUpload code would write when the user changes the file
    // the reason is simply that the ActiveStorage hidden field precedes this hidden
    // field in the HTML and has the same name, so when we submit the last input
    // with this name will be used.

    if (this.hasImageInputHiddenTarget) {
      $(this.imageInputHiddenTarget).remove()
    }

    const file = this.imageInputTarget.files[0]
    if (!file) {
      return
    }

    if (file.size >= this.maxFileByteSize) {
      $(this.imageInputTarget).val(null)

      return this.stopConfirmation('Image must be less than 10 megabytes', false)
    }

    if (this.allowedFileTypes.indexOf(file.type) === -1) {
      $(this.imageInputTarget).val(null)

      return this.stopConfirmation('Image must be a JPG, PNG, SVG, WebP or GIF', false)
    }

    this.data.set('image-blob-path', URL.createObjectURL(file))
    this.startConfirmation()
    this.setThumbnail()
    this.directUploadImage(file)
  }

  directUploadImage(file) {
    const upload = new DirectUpload(
      file,
      '/rails/active_storage/direct_uploads'
    )

    upload.create((error, blob) => {
      if (error) {
        this.stopConfirmation('We encountered an error while uploading your image, please try again or upload a different image', false)
      } else {
        this.uploadImage(blob.signed_id)
      }
    })
  }

  startConfirmation() {
    this.disableFormCta()
    $(this.spinnerTarget).show()
    $(this.messageTarget).hide()
  }

  stopConfirmation(message, success) {
    this.enableFormCta()
    $(this.spinnerTarget).hide()
    $(this.messageTarget).show()
      .addClass(success ? 'text-green-500' : 'text-red-600')
      .removeClass(success ? 'text-red-600' : 'text-green-500')
      .text(message)
  }

  disableFormCta() {
    $(this.imageInputTarget).parents('form').find('input[type="submit"]').attr('disabled', 'disabled')
  }

  enableFormCta() {
    $(this.imageInputTarget).parents('form').find('input[type="submit"]').removeAttr('disabled')
  }

  uploadImage(image) {
    const imageName = this.data.get('image-name')

    $.ajax({
      url: this.data.get('url'),
      type: 'PUT',
      data: { [imageName]: image },
      success: (response) => {
        if (response.error) {
          this.stopConfirmation(response.error, false)
        } else {
          this.stopConfirmation(image ? 'Image uploaded!' : 'Image deleted!',  !!image)
        }
      },
      error: () => {
        this.stopConfirmation('We encountered an error while uploading your image, please try again or upload a different image', false)
      }
    });
  }

  setThumbnail() {
    const imageUrl = this.data.get('image-blob-path')
    const imageName = this.data.get('image-name')

    $(this.imageThumbnailTarget).find('img').remove()

    if (imageUrl && imageUrl !== `/rails/active_storage/blobs//${imageName}`) {
      const thumb = document.createElement('img')
      thumb.src = imageUrl
      thumb.className = 'w-12 overflow-hidden rounded-xl'

      $(this.imageThumbnailTarget).append(thumb).addClass('relative')
      $(this.thumbnailIconTarget).hide()
      $(this.thumbnailButtonTarget).show()
    } else {
      $(this.imageThumbnailTarget).removeClass('relative')
      $(this.thumbnailIconTarget).show()
      $(this.thumbnailButtonTarget).hide()
    }
  }

  clearImage() {
    if (this.hasImageInputHiddenTarget) {
      $(this.imageInputHiddenTarget).find('input').val(null)
    }

    this.uploadImage(null)
    $(this.imageInputTarget).val(null)
    this.data.set('image-blob-path', '')
    this.setThumbnail()
    this.startConfirmation()
  }
}
