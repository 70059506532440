import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'container',
    'form'
  ]

  connect() {
    this.submitForm()
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit')
  }

  onSubmit(e) {
    const response = e.detail[0]
    $(this.containerTarget).html(response.response)
  }
}
