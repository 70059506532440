import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'imageInput',
    'imageInputHidden',
    'imageThumbnail',
    'thumbnailIcon',
    'thumbnailButton'
  ]

  connect() {
    this.setThumbnail()
  }

  onImageChange() {
    // remove the hidden input because we don't need it anymore
    // it's only there for when we reload the page after an error
    // to ensure the image gets resubmitted if the user doesn't change anything
    // but if they do change the file after getting an error, without this,
    // the original file would have been submitted instead of the new one
    // because this hidden field (which has the signed id of the original file)
    // would have taken precedence over the hidden field that the
    // ActiveStorage DirectUpload code would write when the user changes the file
    // the reason is simply that the ActiveStorage hidden field precedes this hidden
    // field in the HTML and has the same name, so when we submit the last input
    // with this name will be used.

    if (this.hasImageInputHiddenTarget) {
      $(this.imageInputHiddenTarget).remove()
    }

    const file = this.imageInputTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('image-blob-path', URL.createObjectURL(file))
    this.setThumbnail()
  }

  setThumbnail() {
    const imageUrl = this.data.get('image-blob-path')
    const imageName = this.data.get('image-name')

    $(this.imageThumbnailTarget).find('img').remove()

    if (imageUrl && imageUrl !== `/rails/active_storage/blobs//${imageName}`) {
      const thumb = document.createElement('img')
      thumb.src = imageUrl
      thumb.className = 'w-12 overflow-hidden rounded-xl'

      $(this.imageThumbnailTarget).append(thumb).addClass('relative')
      $(this.thumbnailIconTarget).hide()
      $(this.thumbnailButtonTarget).show()
    } else {
      $(this.imageThumbnailTarget).removeClass('relative')
      $(this.thumbnailIconTarget).show()
      $(this.thumbnailButtonTarget).hide()
    }
  }

  clearImage() {
    if (this.hasImageInputHiddenTarget) {
      $(this.imageInputHiddenTarget).find('input').val(null)
    }

    $(this.imageTarget).val(null)
    this.data.set('image-blob-path', '')
    this.setThumbnail()
  }
}
