import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'selectContainer',
    'matchForm',
    'activePmsProperty',
    'pmsPropertyIdInput'
  ]

  onConnectClick() {
    $(this.selectContainerTarget).slideToggle()
  }

  onCancelClick() {
    $(this.selectContainerTarget).slideUp()
  }

  submitForm() {
    Rails.fire(this.matchFormTarget, 'submit')
  }

  selectPmsProperty(e) {
    $(this.activePmsPropertyTarget).empty().append(e.target.innerHTML)
    $(this.pmsPropertyIdInputTarget).val(e.target.getAttribute('data-value'))
  }
}
