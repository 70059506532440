import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['icon', 'container', 'title']

  connect() {
    if (this.data.get('index') === '0') {
      this.toggle()
    }
  }

  toggle() {
    $(this.containerTarget).toggleClass('h-6')
    $(this.titleTarget).toggleClass('truncate')
    $(this.iconTarget).toggleClass('-rotate-180')
  }
}
