import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'emailInput'
  ]

  onEmailInput(e) {
    $(this.emailInputTarget).val(e.target.value)
  }
}
