// This Carousel is Based on Max Rolon's for Barrel JQuery Carousel:
// You can check their code snippet here: https://codepen.io/barrel/pen/KKjmJp
// And their amazing tutorial and explanations here: https://archive.barrelny.com/blog/building-a-jquery-slideshow-plugin-from-scratch/

export class Carousel {
  constructor(element, settings) {
    let instanceUid = 0;
    this.defaults = {
      slideDuration: '3000',
      speed: 500,
      arrowRight: '.arrow-right',
      arrowLeft: '.arrow-left',
    };

    this.settings = $.extend({}, this, this.defaults, settings);

    this.initials = {
      currSlide: 0,
      $currSlide: null,
      totalSlides: false,
      csstransitions: false,
    };

    $.extend(this, this.initials);

    this.$el = $(element);

    this.changeSlide = $.proxy(this.changeSlide, this);

    this.instanceUid = instanceUid++;
  }

  init = function () {
    this.csstransitionsTest();

    this.$el.addClass('carousel');
    this.build();
    this.events();
    this.activate();
    this.initTimer();
  };

  csstransitionsTest = function () {
    const elem = document.createElement('modernizr');

    const props = [
      'transition',
      'WebkitTransition',
      'MozTransition',
      'OTransition',
      'msTransition',
    ];

    for (const i in props) {
      const prop = props[i];
      const result = elem.style[prop] !== undefined ? prop : false;
      if (result) {
        this.csstransitions = result;
        break;
      }
    }
  };

  addCSSDuration = function () {
    const _ = this;
    this.$el.find('.slide').each(function () {
      this.style[_.csstransitions + 'Duration'] = _.settings.speed + 'ms';
    });
  };

  removeCSSDuration = function () {
    const _ = this;
    this.$el.find('.slide').each(function () {
      this.style[_.csstransitions + 'Duration'] = '';
    });
  };

  build = function () {
    const $indicators = this.$el
      .append('<ul class="indicators" >')
      .find('.indicators');
    this.totalSlides = this.$el.find('.slide').length;
    for (let i = 0; i < this.totalSlides; i++)
      $indicators.append('<li data-index=' + i + '>');
  };

  activate = function () {
    this.$currSlide = this.$el.find('.slide').eq(0);
    this.$el.find('.indicators li').eq(0).addClass('active');
  };

  events = function () {
    $('body')
      .on(
        'click',
        this.settings.arrowRight,
        { direction: 'right' },
        this.changeSlide
      )
      .on(
        'click',
        this.settings.arrowLeft,
        { direction: 'left' },
        this.changeSlide
      )
      .on('click', '.indicators li', this, this.changeSlide);
  };

  clearTimer = function () {
    if (this.timer) clearInterval(this.timer);
  };

  initTimer = function () {
    this.timer = setInterval(this.changeSlide, this.settings.slideDuration);
  };

  startTimer = function () {
    this.initTimer();
    this.throttle = false;
  };

  changeSlide = function (e) {
    // Ensure that animations are triggered one at a time
    if (this.throttle) return;
    this.throttle = true;

    this.clearTimer();

    const direction = this._direction(e, this.currSlide);

    const animate = this._next(e, direction);
    if (!animate) return;

    const $nextSlide = this.$el
      .find('.slide')
      .eq(this.currSlide)
      .addClass(direction + ' active');

    if (!this.csstransitions) {
      this._jsAnimation($nextSlide, direction);
    } else {
      this._cssAnimation($nextSlide, direction);
    }
  };

  _direction = function (e, currSlide) {
    let direction;

    if (typeof e === 'undefined' || e.data === 'undefined') {
      direction = 'right';
    } else if (
      e.currentTarget !== 'undefined' &&
			e.currentTarget.dataset !== 'undefined' &&
			e.currentTarget.dataset.index !== 'undefined'
    ) {
      const nextIndex = e.currentTarget.dataset.index;

      if (currSlide > nextIndex) {
        direction = 'left';
      } else {
        direction = 'right';
      }
    } else if (e.data.direction !== 'undefined') {
      direction = e.data.direction;
    }

    return direction;
  };

  _next = function (e, direction) {
    // If the event was triggered by a slide indicator, we store the data-index value of that indicator
    const index =
			typeof e !== 'undefined' ? $(e.currentTarget).data('index') : undefined;

    // Logic for determining the next slide
    switch (true) {
    // If the event was triggered by an indicator, we set the next slide based on index
    case typeof index !== 'undefined':
      if (this.currSlide === index) {
        this.startTimer();
        return false;
      }
      this.currSlide = index;
      break;
    case direction === 'right' && this.currSlide < this.totalSlides - 1:
      this.currSlide++;
      break;
    case direction === 'right':
      this.currSlide = 0;
      break;
    case direction === 'left' && this.currSlide === 0:
      this.currSlide = this.totalSlides - 1;
      break;
    case direction === 'left':
      this.currSlide--;
      break;
    }
    return true;
  };

  /**
	 * Executes the animation via CSS transitions
	 * @params	object	Jquery object the next slide to slide into view
	 * @params	string	animation direction
	 * @returns void
	 *
	 */
  _cssAnimation = function ($nextSlide, direction) {
    // Init CSS transitions
    setTimeout(
      function () {
        this.$el.addClass('transition');
        this.addCSSDuration();
        this.$currSlide.addClass('shift-' + direction);
      }.bind(this),
      100
    );

    // CSS Animation Callback
    // After the animation has played out, remove CSS transitions
    // Remove unnecessary classes
    // Start timer
    setTimeout(
      function () {
        this.$el.removeClass('transition');
        this.removeCSSDuration();
        this.$currSlide.removeClass('active shift-left shift-right');
        this.$currSlide = $nextSlide.removeClass(direction);
        this._updateIndicators();
        this.startTimer();
      }.bind(this),
      100 + this.settings.speed
    );
  };

  /**
	 * Executes the animation via JS transitions
	 * @params	object	Jquery object the next slide to slide into view
	 * @params	string	animation direction
	 * @returns void
	 *
	 */
  _jsAnimation = function ($nextSlide, direction) {
    // Cache this reference for use inside animate functions
    const _ = this;

    // See CSS for explanation of .js-reset-left
    if (direction === 'right') _.$currSlide.addClass('js-reset-left');

    const animation = {};
    animation[direction] = '0%';

    const animationPrev = {};
    animationPrev[direction] = '100%';

    // Animation: Current slide
    this.$currSlide.animate(animationPrev, this.settings.speed);

    // Animation: Next slide
    $nextSlide.animate(animation, this.settings.speed, 'swing', function () {
      // Get rid of any JS animation residue
      _.$currSlide.removeClass('active js-reset-left').attr('style', '');
      // Cache the next slide after classes and inline styles have been removed
      _.$currSlide = $nextSlide.removeClass(direction).attr('style', '');
      _._updateIndicators();
      _.startTimer();
    });
  };

  _updateIndicators = function () {
    this.$el
      .find('.indicators li')
      .removeClass('active')
      .eq(this.currSlide)
      .addClass('active');
  };
}
