import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'step'
  ]


  initialize() {
    this.showStep(this.stepTargets[0])
  }

  onStepClick(e) {
    this.showStep(e.currentTarget)
  }

  showStep(stepTarget) {
    $(this.stepTargets).addClass('inactive').removeClass('active')
    $(stepTarget).addClass('active').removeClass('inactive')
  }
}
