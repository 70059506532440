import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'completeButton'
  ]

  initialize() {
    this.totalItems = parseInt(this.data.get('items-count'))
    this.completedItems = parseInt(this.data.get('completed-items-count'))
  }

  connect() {
    if (!this.allItemsCompleted) {
      this.completeButtonTarget.setAttribute('disabled', 'disabled')
    }
  }

  completeItem() {
    this.completedItems += 1

    if (this.allItemsCompleted) {
      this.completeButtonTarget.removeAttribute('disabled')
    }
  }

  get allItemsCompleted() {
    return this.completedItems === this.totalItems
  }
}
