import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'link', 'placeholder'
  ]

  connect() {
    const elapsedTime = Number(this.data.get('elapsed-time'))
    const maxWaitTime = 30
    let waitTime = maxWaitTime - elapsedTime

    $(this.placeholderTarget).show()
    if (waitTime > 0) {
      $(this.placeholderTarget).show()

      const intervalCallback = () => {
        waitTime--

        if (waitTime > 0) {
          $(this.placeholderTarget).html(`Resend code (${waitTime})`)
        } else {
          this.finish()
          clearInterval(this.interval)
        }
      }

      intervalCallback()
      this.interval = setInterval(intervalCallback, 1000)
    } else {
      this.finish()
    }
  }

  finish() {
    $(this.placeholderTarget).hide()
    $(this.linkTarget).show()
  }
}
