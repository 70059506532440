import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'propertyGroupAPsOnline',
    'propertyGroupDevices',
    'propertyGroupGuests',

    'propertyAPsOnline',
    'propertyDevices',
    'propertyGuests'
  ]

  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get('status-url'))
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        json.property_group_statuses.forEach((status) => {
          $(this.propertyGroupAPsOnlineTargets).filter(`[data-id=${status.property_group_id}]`).text(status.aps_online_count)
          $(this.propertyGroupDevicesTargets).filter(`[data-id=${status.property_group_id}]`).text(status.device_count)
          $(this.propertyGroupGuestsTargets).filter(`[data-id=${status.property_group_id}]`).text(status.guest_count)
        })

        json.property_statuses.forEach((status) => {
          $(this.propertyAPsOnlineTargets).filter(`[data-id=${status.property_id}]`).text(status.aps_online_count)
          $(this.propertyDevicesTargets).filter(`[data-id=${status.property_id}]`).text(status.device_count)
          $(this.propertyGuestsTargets).filter(`[data-id=${status.property_id}]`).text(status.guest_count)
        })
      })
  }

  reload() {
    location.reload()
  }
}
