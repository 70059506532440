import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'content', 'spinner']

  showSpinner() {
    $(this.spinnerTarget).show()
    $(this.contentTarget).hide()
  }
}
