import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'unassignedAccessPoints',
    'assignedAccessPoints',
    'accessPointName',
    'accessPointModel',
    'accessPointStatus',
    'accessPointUptime',
    'accessPointConnection',
    'accessPointMenu'
  ]

  connect() {
    this.load()
  }

  load() {
    fetch(this.data.get('status-url'))
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        $(this.unassignedAccessPointsTarget).html(json.unassigned_access_points_html)

        json.assigned_access_point_statuses.forEach((status) => {
          this.updateAssignedAccessPointStatusRow(status)
        })
      })
  }

  updateUnassignedAccessPoints(e) {
    const json = e.detail

    $(this.unassignedAccessPointsTarget).html(json.unassigned_access_points_html)
  }

  updateAssignedAccessPoints(e) {
    const json = e.detail

    $(this.assignedAccessPointsTarget).html(json.assigned_access_points_html)
    json.assigned_access_point_statuses.forEach((status) => {
      this.updateAssignedAccessPointStatusRow(status)
    })
  }

  updateAssignedAccessPointStatusRow(status) {
    $(this.accessPointNameTargets).filter(`[data-id=${status.id}]`).text(status.name)
    $(this.accessPointModelTargets).filter(`[data-id=${status.id}]`).text(status.model_name)
    $(this.accessPointStatusTargets).filter(`[data-id=${status.id}]`).html(status.status_badge_html)
    $(this.accessPointUptimeTargets).filter(`[data-id=${status.id}]`).html(status.uptime_html)
    $(this.accessPointConnectionTargets).filter(`[data-id=${status.id}]`).html(status.connection_html)
    $(this.accessPointMenuTargets).filter(`[data-id=${status.id}]`).html(status.settings_link_html)
  }
}
