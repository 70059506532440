import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'benefitsLinkClosedIcon',
    'benefitsLinkOpenedIcon',
    'benefits'
  ]

  connect() {
    $(this.benefitsTarget).addClass('hidden')
    $(this.benefitsLinkOpenedIconTarget).addClass('hidden')
  }

  toggleBenefits() {
    if ($(this.benefitsTarget).is(':visible')) {
      $(this.benefitsTarget).addClass('hidden')
      $(this.benefitsLinkOpenedIconTarget).addClass('hidden')
      $(this.benefitsLinkClosedIconTarget).removeClass('hidden')
    } else {
      $(this.benefitsTarget).removeClass('hidden')
      $(this.benefitsLinkOpenedIconTarget).removeClass('hidden')
      $(this.benefitsLinkClosedIconTarget).addClass('hidden')
    }
  }
}
