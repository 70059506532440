import { Controller } from 'stimulus'
import _ from 'lodash'

const twitter = require('twitter-text')

export default class extends Controller {
  static targets = [
    'companyName',
    'message',
    'autoresponse',
    'messageLength',
    'autoresponseLength',
    'trackUrls',
    'autoresponseTrackUrls',
    'messagePreview',
    'autoresponsePreview',
    'messageWarningContainer',
    'autoresponseWarningContainer',
    'messageWarningContent',
    'autoresponseWarningContent',
    'messageTooltipContent',
    'autoresponseTooltipContent',
    'fundingWrapper',
    'noCreditsDetails',
    'presetPrices',
    'neededCreditsDetails',
    'spinner',
    'groupTextWithFundingCta',
    'groupTextWithoutFundingCta'
  ]

  connect() {
    this.debouncedGroupTextFundingRefresh = _.debounce(() => {
      this.groupTextfundingRefresh()
    }, 350)

    this.init()
  }

  onCompanyNameChange() {
    this.init()
  }

  init() {
    if (this.hasMessageTarget) {
      this.onMessageChange()
    }
    if (this.hasAutoresponseTarget) {
      this.onAutoresponseChange()
    }
  }

  onMessageChange() {
    const message = $(this.messageTarget).val()
    const trackUrls = this.hasTrackUrlsTarget && this.trackUrlsTarget.checked
    const formattedMessage = this.formatMessage(message, trackUrls)

    $(this.messageWarningContentTarget).html(this.warningForMessage(formattedMessage))
    $(this.messageTooltipContentTarget).html(this.tootlipForMessage(formattedMessage))
    $(this.messageLengthTarget).html(`${formattedMessage.length} characters`)

    if (this.messageUnitsForMessage(formattedMessage) > 1) {
      $(this.messageWarningContainerTarget).show()
    } else {
      $(this.messageWarningContainerTarget).hide()
    }

    this.updatePreview(message, $(this.messagePreviewTarget), trackUrls)
    this.debouncedGroupTextFundingRefresh()
  }

  onAutoresponseChange() {
    const message = $(this.autoresponseTarget).val()
    const trackUrls = this.autoresponseTrackUrlsTarget.checked
    const formattedMessage = this.formatMessage(message, trackUrls)

    $(this.autoresponseWarningContentTarget).html(this.warningForMessage(formattedMessage))
    $(this.autoresponseTooltipContentTarget).html(this.tootlipForMessage(formattedMessage))
    $(this.autoresponseLengthTarget).html(`${formattedMessage.length} characters`)

    if (this.messageUnitsForMessage(formattedMessage) > 1) {
      $(this.autoresponseWarningContainerTarget).show()
    } else {
      $(this.autoresponseWarningContainerTarget).hide()
    }

    this.updatePreview(message, $(this.autoresponsePreviewTarget), trackUrls)
  }

  onMergeFieldClick(e) {
    const { name } = e.target.dataset
    const message = $(this.messageTarget).val()

    $(this.messageTarget).val(`${message}${name}`)
    this.onMessageChange()
  }

  onTrackUrlsChange() {
    this.onMessageChange()
  }

  onAutoresponseTrackUrlsChange() {
    this.onAutoresponseChange()
  }

  updatePreview(message, preview, trackUrls) {
    const urls = twitter.default.extractUrls(message)

    let messageForPreview = `${this.messagePrefix}${message}${this.messageSuffix}`
    if (trackUrls) {
      urls.forEach(url => {
        messageForPreview = messageForPreview.replace(url, `${this.data.get('short-url-host')}/\${code}`)
      })
    }

    if (message.length) {
      preview.text(messageForPreview)
    } else {
      preview.text(this.data.get('empty-message-placeholder'))
    }
  }

  formatMessage(message, trackUrls) {
    const mergeFields = JSON.parse(this.data.get('merge-fields'))
    const urls = twitter.default.extractUrls(message)

    let formattedMessage = `${this.messagePrefix}${message}${this.messageSuffix}`

    if (trackUrls) {
      urls.forEach(url => {
        formattedMessage = formattedMessage.replace(new RegExp(url, 'g'), 'X'.repeat(this.data.get('short-url-length')))
      })
    }

    if (mergeFields) {
      mergeFields.forEach(mergeField => {
        formattedMessage = formattedMessage.replace(new RegExp(mergeField.name, 'g'), 'X'.repeat(mergeField.length))
      })
    }

    return formattedMessage
  }

  messageUnitsForMessage(message) {
    return Math.ceil(message.length / this.messageMaxLengthPerUnit)
  }

  warningForMessage(message) {
    return `
      Messages over ${this.messageMaxLengthPerUnit} characters cost more credits to send.
      Your message of ${message.length} characters requires ${this.messageUnitsForMessage(message)}
      ${this.messageMaxLengthPerUnit} character segments to send which means you will be charged
      ${this.messageUnitsForMessage(message)}x the number of credits per message.
    `
  }

  tootlipForMessage(message) {
    return `
      Your message has a total character count of ${message.length}.
      <br><br>
      This count includes a prefix with your brand name: <strong>"${this.messagePrefix}"</strong> (${this.messagePrefix.length} chars)
      <br><br>
      And a standard suffix of <strong>"${this.messageSuffix}"</strong> (${this.messageSuffix.length} chars)
      which helps improve deliverability of your message by complying with wireless carrier policies.
    `
  }

  groupTextfundingRefresh() {
    const url = this.data.get('group-text-funding-url')
    const message = $(this.messageTarget).val()
    const trackUrls = this.hasTrackUrlsTarget && this.trackUrlsTarget.checked
    const formattedMessage = this.formatMessage(message, trackUrls)

    if (!url) {
      return false
    }

    $(this.spinnerTarget).show()
    $(this.groupTextWithFundingCtaTarget).hide()
    $(this.groupTextWithoutFundingCtaTarget).hide()
    $(this.noCreditsDetailsTarget).hide()
    $(this.fundingWrapperTarget).hide()

    fetch(`${url}?message=${encodeURIComponent(formattedMessage)}`)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        $(this.spinnerTarget).hide()
        $(this.neededCreditsDetailsTarget).html(json.needed_credits_details_html)
        $(this.noCreditsDetailsTarget).html(json.no_credits_needed_html)
        $(this.presetPricesTarget).html(json.available_preset_prices_html)

        const event = new CustomEvent('funding-refreshed')
        window.dispatchEvent(event)

        if (json.require_funding) {
          $(this.fundingWrapperTarget).show()
          $(this.groupTextWithFundingCtaTarget).show()
        } else {
          $(this.noCreditsDetailsTarget).show()
          $(this.groupTextWithoutFundingCtaTarget).show()
        }
      })
  }

  get messagePrefix() {
    const companyName = this.hasCompanyNameTarget ? $(this.companyNameTarget).val() : this.data.get('company-name')
    return companyName + this.data.get('company-name-separator')
  }

  get messageSuffix() {
    return this.data.get('message-suffix')
  }

  get messageMaxLengthPerUnit() {
    return Number(this.data.get('message-max-length-per-unit'))
  }
}
