import { Controller } from 'stimulus'
import * as d3 from 'd3'

export default class extends Controller {
  static targets = [
    'hoverInfo'
  ]

  initialize() {
    this.timeParser = d3.timeParse('%Y-%m-%dT%H:%M:%S%Z')
    this.timeFormatter = d3.timeFormat('%-m/%-d %-I:%M%p')
  }

  showTime(e) {
    const rawTime = e.currentTarget.dataset.time
    const color = e.currentTarget.dataset.online === 'true' ? 'green' : 'red'
    const formattedTime = this.timeFormatter(this.timeParser(rawTime))
    $(this.hoverInfoTarget).html(`<div class="rounded-full w-2 h-2 mr-1.5 bg-${color}-400"></div> ${formattedTime}`)
    $(this.hoverInfoTarget).css(
      'left',
      Math.min(
        $(e.currentTarget).position().left,
        $(this.hoverInfoTarget).parent().width() - $(this.hoverInfoTarget).width()
      )
    )
  }

  showNoData(e) {
    $(this.hoverInfoTarget).html('<div class="rounded-full w-2 h-2 mr-1.5 bg-gray-400"></div> No data')
    $(this.hoverInfoTarget).css(
      'left',
      Math.min(
        $(e.currentTarget).position().left,
        $(this.hoverInfoTarget).parent().width() - $(this.hoverInfoTarget).width()
      )
    )
  }

  hideTime(e) {
    $(this.hoverInfoTarget).html('')
  }
}
