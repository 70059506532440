import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'preview',
    'previewWrapper',
    'submitButton',
    'titleEnabled',
    'title',
    'introductionTextEnabled',
    'introductionText',
    'mainImageImageBlobEnabled',
    'mainImageImageBlob',
    'customTextEnabled',
    'customText',
    'customBlockEnabled',
    'customBlockTitle',
    'customBlockText',
    'customBlockLink',
    'bookDirectEnabled',
    'bookDirectTitle',
    'bookDirectDescription',
    'bookDirectLink',
    'bookDirectPromoCodeEnabled',
    'bookDirectPromoCodeCode',
    'bookDirectPromoCodeText',
    'homepageEnabled',
    'homepageTitle',
    'homepageDescription',
    'homepageLink',
    'homepageAutofillLink',
    'guidebookEnabled',
    'guidebookTitle',
    'guidebookDescription',
    'guidebookLink',
    'guidebookAutofillLink',
    'websiteEnabled',
    'websiteTitle',
    'websiteDescription',
    'websiteLink',
    'socialLinksEnabled',
    'socialLinksFacebookEnabled',
    'socialLinksInstagramEnabled',
    'socialLinksTiktokEnabled',
    'socialLinksYoutubeEnabled',
    'socialLinksLinkedinEnabled'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)

    this.boundFormSubmitEvent = this.formSubmitEvent.bind(this)
    this.boundFormSubmitEnd = this.formSubmitEnd.bind(this)
    document.addEventListener('turbo:submit-start',  this.boundFormSubmitEvent)
    document.addEventListener('turbo:submit-end', this.boundFormSubmitEnd)
  }

  formSubmitEvent(event) {
    if($(event.target).data('target') === 'wifiWelcomeEmailForm') {
      this.submitButtonTarget.innerHTML = '<div class="mx-2.5 spinner spinner--button"></div>'
    }
  }

  formSubmitEnd(event) {
    if($(event.target).data('target') === 'wifiWelcomeEmailForm') {
      if (event.detail.success) {
        this.submitButtonTarget.disabled = true
        this.interval = setInterval(() => { this.poll() }, 1500)
      }
    }
  }
  
  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    this.onChange()
  }

  disconnect() {
    document.removeEventListener('turbo:submit-start', this.boundFormSubmitEvent)
    document.removeEventListener('turbo:submit-end', this.boundFormSubmitEnd)

    clearInterval(this.interval)
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`
    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
    this.adjustIframeHeight()
  }

  adjustIframeHeight() {
    this.previewTargets.forEach(iframe => {
      iframe.onload = () => {
        iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 140) + 'px'
      }
    })
  }

  get previewParams() {
    const params = {
      title: { 
        title: this.titleTarget.value,
        enabled: this.titleEnabledTarget.checked
      },
      introduction_text: {
        introduction_text: this.introductionTextTarget.value,
        enabled: this.introductionTextEnabledTarget.checked
      },
      main_image: {
        url: this.mainImageImageBlobTarget.value,
        enabled: this.mainImageImageBlobEnabledTarget.checked
      },
      custom_text: {
        custom_text: this.customTextTarget.value,
        enabled: this.customTextEnabledTarget.checked
      },
      custom_block: {
        enabled: this.customBlockEnabledTarget.checked,
        title: this.customBlockTitleTarget.value,
        text: this.customBlockTextTarget.value,
        link: this.customBlockLinkTarget.value
      },
      book_direct: {
        title: this.bookDirectTitleTarget.value,
        description: this.bookDirectDescriptionTarget.value,
        link: this.bookDirectLinkTarget.value,
        promo_code_enabled: this.bookDirectPromoCodeEnabledTarget.checked,
        promo_code_code: this.bookDirectPromoCodeCodeTarget.value,
        promo_code_text: this.bookDirectPromoCodeTextTarget.value,
        enabled: this.bookDirectEnabledTarget.checked,
      },
      homepage: {
        title: this.homepageTitleTarget.value,
        description: this.homepageDescriptionTarget.value,
        link: this.homepageLinkTarget.value,
        autofill_link: this.homepageAutofillLinkTarget.value,
        enabled: this.homepageEnabledTarget.checked
      },
      guidebook: {
        title: this.guidebookTitleTarget.value,
        description: this.guidebookDescriptionTarget.value,
        link: this.guidebookLinkTarget.value,
        autofill_link: this.guidebookAutofillLinkTarget.value,
        enabled: this.guidebookEnabledTarget.checked
      },
      website: {
        title: this.websiteTitleTarget.value,
        description: this.websiteDescriptionTarget.value,
        link: this.websiteLinkTarget.value,
        enabled: this.websiteEnabledTarget.checked
      }
    }

    if (this.hasSocialLinksEnabledTarget) {
      params.social_links = {
        enabled: this.socialLinksEnabledTarget.checked,
        facebook_enabled: (this.hasSocialLinksFacebookEnabledTarget ? this.socialLinksFacebookEnabledTarget.checked : false),
        instagram_enabled: (this.hasSocialLinksInstagramEnabledTarget ? this.socialLinksInstagramEnabledTarget.checked : false),
        tiktok_enabled: (this.hasSocialLinksTiktokEnabledTarget ? this.socialLinksTiktokEnabledTarget.checked : false),
        youtube_enabled: (this.hasSocialLinksYoutubeEnabledTarget ? this.socialLinksYoutubeEnabledTarget.checked : false),
        linkedin_enabled: (this.hasSocialLinksLinkedinEnabledTarget ? this.socialLinksLinkedinEnabledTarget.checked : false)
      }
    }

    return params
  }

  poll() {
    const url = this.data.get('status-check-path')
    
    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (json.campaign_monitor_automation_id) {
          clearInterval(this.interval)
          window.location.href = json.redirect_path; 
        }
      })
  }
}
