import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import { eventTracker } from '../../utilities/customer'

export default class extends Controller {
  static targets = [
    'messageContent',
    'daysBeforeArrival',
    'error',
    'activationCheckbox',
    'activationCta',
    'activationForm'
  ]

  initialize() {
    this.debouncedSaveMessageContent = _.debounce(() => {
      this.saveMessageContent()
    }, 300)
  }

  onMessageChange() {
    this.debouncedSaveMessageContent()
  }

  onMergeFieldClick(e) {
    const { name } = e.target.dataset
    const message = $(this.messageContentTarget).val()

    $(this.messageContentTarget).val(`${message} ${name}`)
    this.onMessageChange()
  }

  onActiveChange() {
    if (this.activationCheckboxTarget.value === 'true') {
      this.activationCheckboxTarget.value = false
      $(this.activationCtaTarget).removeClass('enabled')

      this.trackActivation(false)
      this.submitActivationForm()
    } else {
      document.dispatchEvent(new CustomEvent('show_modal', {
        detail: {
          url: this.data.get('pre-arrival-activation-confirmation-path').replace('days_before_arrival_placeholder', this.daysBeforeArrivalTarget.value)
        }
      }))
    }
  }

  updatePropertyMessages() {
    document.dispatchEvent(new CustomEvent('pre_arrival_message_template_changed', {
      detail: {
        message: $(this.messageContentTarget).val()
      }
    }))
  }

  activate() {
    this.activationCheckboxTarget.value = true
    $(this.activationCtaTarget).addClass('enabled')

    this.trackActivation(true)
    this.submitActivationForm()
    document.dispatchEvent(new CustomEvent('close_modal'))
  }

  submitActivationForm() {
    $(this.activationCtaTarget).attr('disabled', 'disabled')
    Rails.fire(this.activationFormTarget, 'submit')
  }

  saveMessageContent() {
    $.ajax({
      url: this.data.get('url'),
      type: 'PUT',
      data: {
        content: this.messageContentTarget.value,
        days_before_arrival: this.hasDaysBeforeArrivalTarget ? this.daysBeforeArrivalTarget.value : null
      }
    }).done(data => {
      if (data.error) {
        $(this.errorTarget).text(data.error)
      } else {
        $(this.errorTarget).text('')
        this.updatePropertyMessages()
        this.trackMessageChange()
      }
    })
  }

  trackActivation(confirmation) {
    eventTracker.track('global_toggle.enabled', {
      flow: 'pre_arrival_messaging_automated',
      object: 'global_toggle',
      action: 'enabled',
      confirmation,
      days: this.daysBeforeArrivalTarget.value
    })
  }

  trackMessageChange() {
    eventTracker.track('template.edited', {
      flow: 'pre_arrival_messaging_automated',
      object: 'tempalte',
      action: 'edited'
    })
  }
}
