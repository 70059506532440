import { Controller } from 'stimulus'
import { eventTracker } from '../../utilities/customer'

export default class extends Controller {
  static targets = [
    'rentalCompanyIdInput',
    'stayfiTopicInput',
    'topicFields',
    'hiddenFields',
    'showFieldsButton',
    'atProperty',
    'atPropertyFields',
    'notAtProperty',
    'notAtPropertyFields',
    'submitTicket',
    'submitTicketForm',
    'scheduleCall',
    'scheduleCallForm'
  ]

  connect() {
    if (this.data.get('show-fields') === 'true') {
      if (this.hasShowFieldsButtonTarget) {
        $(this.showFieldsButtonTarget).addClass('hidden')
      }

      if (this.hasHiddenFieldsTarget) {
        $(this.hiddenFieldsTarget).removeClass('hidden')
      }
    }
  }

  createEvent(flow, object, action, extraProperties) {
    const properties = Object.assign({}, { flow, object, action }, extraProperties)
    eventTracker.track(`${object}.${action}`, properties)
  }

  showHiddenFields(e) {
    e.preventDefault()
    this.createEvent('ticket_creation', 'contact_support', 'clicked', { status: true } )
    $(this.showFieldsButtonTarget).addClass('hidden')
    $(this.hiddenFieldsTarget).removeClass('hidden')
  }

  changeTopicOrCompany(e) {
    const stayfiTopic = $(this.stayfiTopicInputTarget).val()

    if (stayfiTopic) {
      $.get(this.data.get('url'), {
        stayfi_topic: stayfiTopic,
        rental_company_id: $(this.rentalCompanyIdInputTarget).val()
      }).done(data => {
        this.topicFieldsTarget.innerHTML = data
      })
    } else {
      this.topicFieldsTarget.innerHTML = ''
    }
  }

  resetPropertyFields() {
    $(this.atPropertyTarget).removeClass('bg-blue-500 text-white').addClass('bg-white text-blue-700')
    $(this.atPropertyFieldsTarget).addClass('hidden')
    $(this.notAtPropertyTarget).removeClass('bg-blue-500 text-white').addClass('bg-white text-blue-700')
    $(this.notAtPropertyFieldsTarget).addClass('hidden')
  }

  showAtPropertyFields(e) {
    e.preventDefault()
    this.resetPropertyFields()
    this.createEvent('ticket_creation', 'at_property', 'clicked', { status: true })
    $(this.atPropertyTarget).removeClass('bg-white text-blue-700').addClass('bg-blue-500 text-white')
    $(this.atPropertyFieldsTarget).removeClass('hidden')
  }

  showNotAtPropertyFields(e) {
    e.preventDefault()
    this.resetPropertyFields()
    this.createEvent('ticket_creation', 'at_property', 'clicked', { status: false })
    $(this.notAtPropertyTarget).removeClass('bg-white text-blue-700').addClass('bg-blue-500 text-white')
    $(this.notAtPropertyFieldsTarget).removeClass('hidden')
  }

  resetTicketAndCall() {
    $(this.submitTicketTargets).removeClass('bg-blue-500 text-white').addClass('bg-white text-blue-700')
    $(this.submitTicketFormTargets).addClass('hidden')
    $(this.scheduleCallTargets).removeClass('bg-blue-500 text-white').addClass('bg-white text-blue-700')
    $(this.scheduleCallFormTargets).addClass('hidden')
  }

  submitTicket(e) {
    e.preventDefault()
    this.resetTicketAndCall()
    this.createEvent('ticket_creation', 'ticket_form', 'displayed', { type: 'wifi_device_issue' })
    $(this.submitTicketTargets).removeClass('bg-white text-blue-700').addClass('bg-blue-500 text-white')
    $(this.submitTicketFormTargets).removeClass('hidden')
  }

  scheduleCall(e) {
    e.preventDefault()
    this.resetTicketAndCall()
    this.createEvent('ticket_creation', 'schedule_call', 'clicked')
    $(this.scheduleCallTargets).removeClass('bg-white text-blue-700').addClass('bg-blue-500 text-white')
    $(this.scheduleCallFormTargets).removeClass('hidden')
  }

  trackHelpArticleClicks(e) {
    this.createEvent('ticket_creation', 'help_article', 'clicked', { redirect_url: e.target.dataset.redirectUrl } )
  }
}
