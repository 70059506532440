import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'contentContainer', 'spinner' ]

  connect() {
    this.load()
  }

  load() {
    this.loading = true
    this.contentContainerTarget.innerHTML = ''

    fetch(this.data.get('url'))
      .then(response => response.text())
      .then(html => {
        this.contentContainerTarget.innerHTML = html
        this.loading = false
      })
  }

  set loading(val) {
    if (this.hasSpinnerTarget) {
      if (val) {
        $(this.spinnerTarget).show()
      } else {
        $(this.spinnerTarget).hide()
      }
    }
  }
}
