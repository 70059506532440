import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'dataCollectionActivationCheckbox',
    'dataCollectionActivationCta',
    'dataCollectionActivationForm'
  ]

  onDataCollectionActiveChange() {
    this.toggleCheckboxValue()
    this.submitDataCollectionActivationForm()
  }

  submitDataCollectionActivationForm() {
    Rails.fire(this.dataCollectionActivationFormTarget, 'submit')
  }

  toggleCheckboxValue() {
    const value = this.dataCollectionActivationCheckboxTarget.value === 'true'
    const $ctaTarget = $(this.dataCollectionActivationCtaTarget)

    this.dataCollectionActivationCheckboxTarget.value = !value
    value ? $ctaTarget.removeClass('enabled') : $ctaTarget.addClass('enabled')
  }
}
