import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'element'
  ]

  connect() {
    this.elementTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
