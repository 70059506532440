import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'homePagePreviewButton',
    'splashPagePreviewButton',
    'preview',
    'companyWebsiteUrl',
    'facebookUrl',
    'instagramUrl',
    'tiktokUrl',
    'logo'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }

  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    $(this.previewTargets).css('width', '23rem').removeClass('w-full')


    if (this.data.get('logo-blob-path')) {
      this.data.set('logo-url', this.data.get('logo-blob-path'))
    }

    if (this.data.get('splash-page-preview-path')) {
      this.showSplashPage()
    } else if (this.data.get('home-page-preview-path')) {
      this.showHomePage()
    }
  }

  onLogoChange() {
    const file = this.logoTarget.files[0]
    if (!file) {
      return
    }

    this.data.set('logo-url', file && URL.createObjectURL(file))
    this.onChange()
  }

  onChange() {
    this.debouncedRefreshPreview()
  }


  showSplashPage() {
    if (this.hasHomePagePreviewButtonTarget) {
      $(this.homePagePreviewButtonTarget).removeClass('bg-white').addClass('bg-gray-200')
      $(this.splashPagePreviewButtonTarget).removeClass('bg-gray-200').addClass('bg-white')
    }

    this.data.set('current-preview-path', this.data.get('splash-page-preview-path'))
    this.refreshPreview()
  }

  showHomePage() {
    if (this.hasHomePagePreviewButtonTarget) {
      $(this.homePagePreviewButtonTarget).addClass('bg-white').removeClass('bg-gray-200')
      $(this.splashPagePreviewButtonTarget).addClass('bg-gray-200').removeClass('bg-white')
    }

    this.data.set('current-preview-path', this.data.get('home-page-preview-path'))
    this.refreshPreview()
  }

  refreshPreview() {
    const src = `${this.data.get('current-preview-path')}?${$.param(this.previewParams)}`

    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
  }


  get previewParams() {
    return {
      rental_property_id: this.data.get('rental-property-id'),
      logo_url: this.data.get('logo-url'),
      company_website_url: this.companyWebsiteUrlTarget.value,
      facebook_url: this.facebookUrlTarget.value,
      instagram_url: this.instagramUrlTarget.value,
      tiktok_url: this.tiktokUrlTarget.value
    }
  }
}
