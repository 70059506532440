import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'propertyButtonOne',
    'propertyButtonTwo',
    'propertyButtonThree',
    'propertyButtonFour',
    'propertyOneWrapper',
    'propertyTwoWrapper',
    'propertyThreeWrapper',
    'propertyFourWrapper',
    'numberOfProperties',
    'buttonGroup',
    'propertyOneName',
    'propertyOneDescription',
    'propertyOneImage',
    'propertyOneLink',
    'propertyTwoName',
    'propertyTwoDescription',
    'propertyTwoImage',
    'propertyTwoLink',
    'propertyThreeName',
    'propertyThreeDescription',
    'propertyThreeImage',
    'propertyThreeLink',
    'propertyFourName',
    'propertyFourDescription',
    'propertyFourImage',
    'propertyFourLink',

  ]

  connect() {
    this.load()
  }

  load() {
    this.numberOfPropertiesChanged()
    this.selectTabWithErrorsOrFirstTab()
  }

  selectTabWithErrorsOrFirstTab() {
    if (this.propertyOneWrapperTarget.querySelector('.is-invalid')) {
      this.handleVisiblePropertyNumber(this.propertyButtonOneTarget)
    } else if (this.propertyTwoWrapperTarget.querySelector('.is-invalid')) {
      this.handleVisiblePropertyNumber(this.propertyButtonTwoTarget)
    } else if (this.propertyThreeWrapperTarget.querySelector('.is-invalid')) {
      this.handleVisiblePropertyNumber(this.propertyButtonThreeTarget)
    } else if (this.propertyFourWrapperTarget.querySelector('.is-invalid')) {
      this.handleVisiblePropertyNumber(this.propertyButtonFourTarget)
    } else {
      this.handleVisiblePropertyNumber(this.propertyButtonOneTarget)
    }
  }

  numberOfPropertiesChanged(e) {
    this.handleVisiblePropertyNumber(this.propertyButtonOneTarget)

    let buttonClassesToRemove = []
    let buttonClassesToAdd = null
    let activeButtonsToUpdate = []
    let hiddenButtonsToUpdate = []

    if (this.numberOfPropertiesTarget.value.toString() === '1') {
      this.buttonGroupTarget.classList.add('hidden')
    } else if (this.numberOfPropertiesTarget.value.toString() === '2') {
      this.buttonGroupTarget.classList.remove('hidden')
      this.propertyButtonTwoTarget.classList.add('rounded-r-md')

      buttonClassesToRemove = ['w-1/3', 'w-1/4', 'hidden']
      buttonClassesToAdd = ['w-1/2']
      activeButtonsToUpdate = [this.propertyButtonOneTarget, this.propertyButtonTwoTarget]
      hiddenButtonsToUpdate = [this.propertyButtonThreeTarget, this.propertyButtonFourTarget]
    } else if (this.numberOfPropertiesTarget.value.toString() === '3') {
      this.buttonGroupTarget.classList.remove('hidden')
      this.propertyButtonTwoTarget.classList.remove('rounded-r-md')
      this.propertyButtonThreeTarget.classList.add('rounded-r-md')

      buttonClassesToRemove = ['w-1/2', 'w-1/4', 'hidden']
      buttonClassesToAdd = ['w-1/3']
      activeButtonsToUpdate = [this.propertyButtonOneTarget, this.propertyButtonTwoTarget, this.propertyButtonThreeTarget]
      hiddenButtonsToUpdate = [this.propertyButtonFourTarget]
    } else if (this.numberOfPropertiesTarget.value.toString() === '4') {
      this.buttonGroupTarget.classList.remove('hidden')
      this.propertyButtonTwoTarget.classList.remove('rounded-r-md')
      this.propertyButtonThreeTarget.classList.remove('rounded-r-md')

      buttonClassesToRemove = ['w-1/2', 'w-1/3', 'hidden']
      buttonClassesToAdd = ['w-1/4']
      activeButtonsToUpdate = [
        this.propertyButtonOneTarget, this.propertyButtonTwoTarget,
        this.propertyButtonThreeTarget, this.propertyButtonFourTarget
      ]
    }

    activeButtonsToUpdate.forEach((button) => {
      button.classList.remove(...buttonClassesToRemove)
      button.classList.add(...buttonClassesToAdd)
    })

    hiddenButtonsToUpdate.forEach((button) => {
      button.classList.add('hidden')
    })
  }

  handleVisiblePropertyNumber(target) {
    const buttons = [
      this.propertyButtonOneTarget,
      this.propertyButtonTwoTarget,
      this.propertyButtonThreeTarget,
      this.propertyButtonFourTarget
    ];

    const wrappers = [
      this.propertyOneWrapperTarget,
      this.propertyTwoWrapperTarget,
      this.propertyThreeWrapperTarget,
      this.propertyFourWrapperTarget
    ];

    buttons.forEach((button, index) => {
      if (target === button) {
        button.classList.add('active-button');
        wrappers[index].classList.remove('hidden');
      } else {
        button.classList.remove('active-button');
        wrappers[index].classList.add('hidden');
      }
    });
  }

  propertyButtonClicked(e) {
    e.preventDefault()

    this.handleVisiblePropertyNumber(e.target)
  }
}
