import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'enabledCheckbox',
    'enabledToggle',
    'contentArea',
    'urlInput',
    'importWebsiteUrl',
    'formFieldToDisable',
    'websiteImportBanner',
  ]

  connect() {
    if (this.enabledCheckboxTarget.value === 'true') {
      $(this.enabledToggleTarget).addClass('enabled')
      $(this.contentAreaTarget).show()
    }

    if (
      this.hasImportWebsiteUrlTarget &&
			this.importWebsiteUrlTarget.value === 'true'
    ) {
      this.onImportWebsiteUrlChange()
    }
  }

  toggle(e) {
    if (this.enabledCheckboxTarget.value === 'true') {
      $(this.enabledToggleTarget).removeClass('enabled')
      this.enabledCheckboxTarget.value = false
      $(this.contentAreaTarget).slideUp()
    } else {
      $(this.enabledToggleTarget).addClass('enabled')
      this.enabledCheckboxTarget.value = true
      $(this.contentAreaTarget).slideDown()
    }
  }

  clearInput() {
    $(this.urlInputTarget).val(null)
  }

  onImportWebsiteUrlChange() {
    if (this.importWebsiteUrlTarget.value === 'true') {
      if (this.hasWebsiteImportBannerTarget) {
        $(this.websiteImportBannerTarget).show()
        $(this.formFieldToDisableTarget).hide()
      } else {
        $(this.formFieldToDisableTarget)
          .show()
          .attr('disabled', 'disabled')
          .val(this.data.get('template-website-url'))
      }
    } else {
      $(this.formFieldToDisableTarget)
        .show()
        .removeAttr('disabled')
        .val(this.data.get('property-website-url'))

      if (this.hasWebsiteImportBannerTarget) {
        $(this.websiteImportBannerTarget).hide()
      }
    }
  }
}
