import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    if (this.rentalManagerHasHubspot) {
      window.hsConversationsSettings = {
        loadImmediately: false
      }

      fetch(this.data.get('generate-visitor-token-url'), { method: 'POST' })
        .then(response => response.text())
        .then(text => JSON.parse(text))
        .then(json => {
          window.hsConversationsSettings.identificationToken = json.identification_token
          window.hsConversationsSettings.identificationEmail = json.identification_email

          const loadChatWidget = () => {
            window.HubSpotConversations.widget.load()
          }

          if (window.HubSpotConversations) {
            loadChatWidget()
          } else {
            window.hsConversationsOnReady = [loadChatWidget]
          }
        })
    }

    this.injectHubspotScriptTag()
  }

  injectHubspotScriptTag() {
    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.src = this.data.get('hubspot-tracking-code-url')
    scriptTag.id = 'hs-script-loader'
    scriptTag.async = true
    scriptTag.defer = true

    document.body.appendChild(scriptTag)
  }

  get rentalManagerHasHubspot() {
    return this.data.get('rental-manager-has-hubspot') === 'true'
  }
}
