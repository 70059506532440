import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'step1Current',
    'step1Completed',
    'step1Title',
    'step1Contents',

    'step2Inactive',
    'step2Current',
    'step2Completed',
    'step2Title',
    'step2Contents',

    'step3Inactive',
    'step3Current',
    'step3Completed',
    'step3Title',
    'step3Contents'
  ]

  connect() {
    const step = parseInt(this.data.get('initial-step'))

    if (step === 3) {
      this.gotoStep3()
    } else if (step === 2) {
      this.gotoStep2()
    } else {
      this.gotoStep1()
    }
  }

  gotoStep1() {
    this.step1Active()
    this.step2Inactive()

    if (this.data.get('partially-connected') === 'true' && this.data.get('hostfully-api-v3-enabled') === 'false') {
      this.step3Complete()
    } else {
      this.step3Inactive()
    }
  }

  gotoStep2() {
    this.step1Complete()
    this.step2Active()

    if (this.data.get('partially-connected') === 'true' && this.data.get('hostfully-api-v3-enabled') === 'false') {
      this.step3Complete()
    } else {
      this.step3Inactive()
    }
  }

  gotoStep3() {
    this.step1Complete()
    this.step2Complete()
    this.step3Active()
  }

  step1Active() {
    $(this.step1ContentsTarget).removeClass('hidden')
    $(this.step1CurrentTarget).removeClass('hidden')
    $(this.step1CompletedTarget).addClass('hidden')
    $(this.step1TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-blue-600')
  }

  step1Complete() {
    $(this.step1ContentsTarget).addClass('hidden')
    $(this.step1CurrentTarget).addClass('hidden')
    $(this.step1CompletedTarget).removeClass('hidden')
    $(this.step1TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-gray-900')
  }

  step2Inactive() {
    $(this.step2ContentsTarget).addClass('hidden')
    $(this.step2InactiveTarget).removeClass('hidden')
    $(this.step2CurrentTarget).addClass('hidden')
    $(this.step2CompletedTarget).addClass('hidden')
    $(this.step2TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-gray-500')
  }

  step2Active() {
    $(this.step2ContentsTarget).removeClass('hidden')
    $(this.step2InactiveTarget).addClass('hidden')
    $(this.step2CurrentTarget).removeClass('hidden')
    $(this.step2CompletedTarget).addClass('hidden')
    $(this.step2TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-blue-600')
  }

  step2Complete() {
    $(this.step2ContentsTarget).addClass('hidden')
    $(this.step2InactiveTarget).addClass('hidden')
    $(this.step2CurrentTarget).addClass('hidden')
    $(this.step2CompletedTarget).removeClass('hidden')
    $(this.step2TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-gray-900')
  }

  step3Inactive() {
    $(this.step3ContentsTarget).addClass('hidden')
    $(this.step3InactiveTarget).removeClass('hidden')
    $(this.step3CurrentTarget).addClass('hidden')
    $(this.step3CompletedTarget).addClass('hidden')
    $(this.step3TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-gray-500')
  }

  step3Active() {
    $(this.step3ContentsTarget).removeClass('hidden')
    $(this.step3InactiveTarget).addClass('hidden')
    $(this.step3CurrentTarget).removeClass('hidden')
    $(this.step3CompletedTarget).addClass('hidden')
    $(this.step3TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-blue-600')
  }

  step3Complete() {
    $(this.step3ContentsTarget).addClass('hidden')
    $(this.step3InactiveTarget).addClass('hidden')
    $(this.step3CurrentTarget).addClass('hidden')
    $(this.step3CompletedTarget).removeClass('hidden')
    $(this.step3TitleTarget).removeClass('text-gray-900 text-blue-600 text-gray-500').addClass('text-gray-900')
  }
}
