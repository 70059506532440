import { Controller } from 'stimulus'

export default class extends Controller {

  connect() {
    if (this.data.get('reload') === 'true') {
      this.pollEndpoint()
    }
  }

  pollEndpoint() {
    const connectionUrl = this.data.get('url')
    const interval = setInterval(() => {
      fetch(connectionUrl)
        .then(response => response.json())
        .then(data => {
          if (data.connected === true || data.connected === 1) {
            clearInterval(interval)
            location.reload()
          }
        })
    }, 3000)
  }
}