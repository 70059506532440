import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'layoutId',
    'layoutTile',
  ]

  selectLayout(event) {
    this.layoutTileTargets.forEach((element) => {
      element.classList.remove('active')
    })
    event.currentTarget.classList.add('active')
    this.layoutIdTarget.value = event.currentTarget.dataset.layoutId
  }
}

