import { Controller } from 'stimulus'

export default class extends Controller {
  onSubmit(e) {
    const response = e.detail[0];
    const status = response.status;
    const json = JSON.parse(response.responseText)

    if (status === 422) {
      $(this.element).html(json.access_point_assignment_form)
    } else if (status === 200) {
      document.dispatchEvent(new CustomEvent('unassigned_access_points_refresh', { detail: json }))
      document.dispatchEvent(new CustomEvent('assigned_access_points_refresh', { detail: json }))
    }
  }
}
