import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'autorefill',
    'oneTime',
    'field',
    'button',
    'creditsAmount',
    'refillCredits',
    'fundingType',
    'minimumNeededCreditButton'
  ]

  initialize() {
    this.debouncedSmsCreditPriceFetch = _.debounce(() => {
      this.smsCreditPriceFetch()
    }, 200)
  }

  connect() {
    if (!this.hasAutorefillTarget && !this.hasOnetimeTarget) {
      this.updateOneTimeFundingButton($(this.creditsAmountTarget).val())

      return
    }

    if ($(this.fundingTypeTarget).val() === 'autorefill') {
      this.showAutorefill()
    } else {
      this.showOneTime()
    }
  }

  showOneTime() {
    $(this.fundingTypeTarget).val('onetime')

    $(this.oneTimeTarget).removeClass('closed').addClass('open')
    $(this.autorefillTarget).removeClass('open').addClass('closed')

    this.updateOneTimeFundingButton($(this.creditsAmountTarget).val())
  }

  showAutorefill() {
    $(this.fundingTypeTarget).val('autorefill')

    $(this.autorefillTarget).removeClass('closed').addClass('open')
    $(this.oneTimeTarget).removeClass('open').addClass('closed')

    this.updateAutoRefillButtonText()
  }

  onPresetValueChange(event) {
    const { currentTarget, type } = event
    const element = currentTarget
    const dataset = type === 'funding-refreshed' ? this.minimumNeededCreditButtonTarget.dataset : element.dataset
    const { credits, totalAmount } = dataset
    this.creditsAmountTarget.value = credits
    this.fieldTarget.value = this.formatNumber(totalAmount)
    this.updateOneTimeFundingButton(credits)
  }

  onCreditsAmountChange(event) {
    const element = event.target
    const { value } = element

    this.debouncedSmsCreditPriceFetch()
    this.updateOneTimeFundingButton(value)
  }

  smsCreditPriceFetch() {
    const url = this.data.get('sms-credit-price-url')
    const minimumCreditsPurchase = this.data.get('minimum-credits-purchase')
    const creditsAmount = this.creditsAmountTarget.value

    if (Number(creditsAmount) >= Number(minimumCreditsPurchase)) {
      fetch(`${url}?credits_amount=${creditsAmount}`)
        .then(response => response.text())
        .then(text => JSON.parse(text))
        .then(json => {
          this.fieldTarget.value = this.formatNumber(json.total_amount)
        })
    }
  }

  updateAutoRefillButtonText() {
    const text = `Purchase ${Number($(this.refillCreditsTarget).val()).toLocaleString()} credit Auto-Refill & ${this.data.get('cta-suffix')}`
    this.buttonTarget.value = text
    this.buttonTarget.dataset.disableWith = text
  }

  updateOneTimeFundingButton(value) {
    const text = `Purchase ${Number(value).toLocaleString()} Credits & ${this.data.get('cta-suffix')}`
    this.buttonTarget.value = text
    this.buttonTarget.dataset.disableWith = text
  }

  formatNumber(value) {
    return Number(value).toFixed(2).replace('.00', '')
  }
}
