import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'pmsProviderInput',
    'otherPMSProviderContainer'
  ]

  connect() {
    this.toggleOtherPMSProvider()
  }

  toggleOtherPMSProvider() {
    if ($(this.pmsProviderInputTarget).val() === 'other') {
      $(this.otherPMSProviderContainerTarget).show()
    } else {
      $(this.otherPMSProviderContainerTarget).hide()
    }
  }
}
