import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'checkbox',
    'thumb'
  ]

  connect() {
    if (this.checkboxTarget.checked || this.checkboxTarget.value === 'true') {
      $(this.thumbTarget).addClass('enabled')
    }
  }

  toggle() {
    if (this.checkboxTarget.checked || this.checkboxTarget.value === 'true') {
      $(this.thumbTarget).removeClass('enabled')
      this.checkboxTarget.checked = false
      this.checkboxTarget.value = false
    } else {
      $(this.thumbTarget).addClass('enabled')
      this.checkboxTarget.checked = true
      this.checkboxTarget.value = true
    }
  }
}
