import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'mobileMenu', 'menuOverlay', 'menu' ]

  showMenu(e) {
    $(this.mobileMenuTarget).show()
    $(this.menuOverlayTarget).addClass('opacity-100').removeClass('opacity-0')
    $(this.menuTarget).addClass('translate-x-0').removeClass('-translate-x-full')
  }

  hideMenu(e) {
    $(this.menuTarget).addClass('-translate-x-full').removeClass('translate-x-0')
    $(this.menuOverlayTarget).removeClass('opacity-100').addClass('opacity-0').one('transitionend', (event) => {
      $(this.mobileMenuTarget).hide()
    })
  }
}
