import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['latestTextMessageCreditRefillIntent', 'field', 'button', 'creditsAmount']

  initialize() {
    this.debouncedSmsCreditPriceFetch = _.debounce(() => {
      this.smsCreditPriceFetch()
    }, 200)
  }

  connect() {
    this.startPolling()
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 2000)
  }

  poll() {
    const url = this.data.get('url')
    
    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        $(this.latestTextMessageCreditRefillIntentTarget).html(json.text_message_credit_refill_intent_status)

        if (this.hasCreditsAmountTarget) {
          if (json.finished) {
            this.updateButton(this.creditsAmountTarget.value)
            this.buttonTarget.removeAttribute('disabled')
            this.creditsAmountTarget.removeAttribute('readonly')
          } else {
            this.buttonTarget.value = 'Processing...'
            this.buttonTarget.setAttribute('disabled', 'disabled')
            this.creditsAmountTarget.setAttribute('readonly', 'readonly')
          }
        } else {
          if (json.finished) {
            this.buttonTarget.value = 'Submit'
            this.buttonTarget.removeAttribute('disabled')
            this.fieldTarget.removeAttribute('readonly')
          } else {
            this.buttonTarget.value = 'Processing...'
            this.buttonTarget.setAttribute('disabled', 'disabled')
            this.fieldTarget.setAttribute('readonly', 'readonly')
          }
        }
      })
  }

  onPresetValueChange(event) {
    const element = event.currentTarget
    const { dataset } = element
    const { credits, totalAmount } = dataset
    this.creditsAmountTarget.value = credits
    this.fieldTarget.value = this.formatNumber(totalAmount)
    this.updateButton(credits)
  }

  onCreditsAmountChange(event) {
    const element = event.target
    const { value } = element

    this.debouncedSmsCreditPriceFetch()
    this.updateButton(value)
  }

  smsCreditPriceFetch() {
    const url = this.data.get('sms-credit-price-url')
    const minimumCreditsPurchase = this.data.get('minimum-credits-purchase')
    const creditsAmount = this.creditsAmountTarget.value

    if (Number(creditsAmount) >= Number(minimumCreditsPurchase)) {
      fetch(`${url}?credits_amount=${creditsAmount}`)
        .then(response => response.text())
        .then(text => JSON.parse(text))
        .then(json => {
          this.fieldTarget.value = this.formatNumber(json.total_amount)
        })
    }
  }

  updateButton(value) {
    const text = `Purchase ${Number(value).toLocaleString()} credits`
    this.buttonTarget.value = text
    this.buttonTarget.dataset.disableWith = text
  }

  formatNumber(value) {
    return Number(value).toFixed(2).replace('.00', '')
  }
}
