import { each, every,  remove, includes } from 'lodash'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'formContainer',
    'warning',
    'propertyCheckbox',
    'allPropertiesCheckbox',
    'button',
    'searchContainer',
    'selectedPropertyIdsInput',
    'allPropertyIdsInput'
  ]

  onPropertyGroupSelect(e) {
    if ($(e.currentTarget).val() === 'add') {
      $(e.currentTarget).val('')
      document.dispatchEvent(new CustomEvent('show_modal', {
        detail: {
          url: this.data.get('new-property-group-url')
        }
      }))
    }
  }

  connect() {
    this.startPolling()

    if (this.hasButtonTarget) {
      this.updateButton()
    }
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 1500)
  }

  poll() {
    const url = this.data.get('status-url')

    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (json.finished) {
          this.reload()
          clearInterval(this.interval)
        }
      })
  }

  reload() {
    const url = this.data.get('form-url')

    fetch(url)
      .then(response => response.text())
      .then(text => {
        if (this.hasWarningTarget) {
          $(this.warningTarget).html('')
        }

        $(this.formContainerTarget).html(text)

        if (this.hasButtonTarget) {
          this.updateButton()
        }
      })
  }

  toggleProperty() {
    const ids = this.selectedPropertyIdsInputTarget.value ? this.selectedPropertyIdsInputTarget.value.split(',') : []

    each(this.propertyCheckboxTargets, checkbox => {
      const propertyId = checkbox.dataset.propertyId

      if (checkbox.checked) {
        if (!includes(ids, propertyId)) {
          ids.push(propertyId)
        }
      } else {
        remove(ids, (id) => id === propertyId)
      }
    })

    if (every(this.propertyCheckboxTargets, checkbox => checkbox.checked)) {
      this.allPropertiesCheckboxTarget.checked = true
    } else {
      this.allPropertiesCheckboxTarget.checked = false
    }

    this.selectedPropertyIdsInputTarget.value = ids.join(',')

    this.updateButton()
  }

  toggleProperties() {
    const ids = this.selectedPropertyIdsInputTarget.value ? this.selectedPropertyIdsInputTarget.value.split(',') : []

    each(this.propertyCheckboxTargets, checkbox => {
      const propertyId = checkbox.dataset.propertyId

      if (includes(ids, propertyId)) {
        checkbox.checked = true
      } else {
        checkbox.checked = false
      }
    })
  }

  toggleAllProperties() {
    if (this.allPropertiesCheckboxTarget.checked) {
      each(this.propertyCheckboxTargets, checkbox => {
        checkbox.checked = true
        this.selectedPropertyIdsInputTarget.value = this.allPropertyIdsInputTarget.value
      })
    } else {
      each(this.propertyCheckboxTargets, checkbox => {
        checkbox.checked = false
        this.selectedPropertyIdsInputTarget.value = null
      })
    }

    this.updateButton()
  }

  updateButton() {
    const ids = this.selectedPropertyIdsInputTarget.value ? this.selectedPropertyIdsInputTarget.value.split(',') : []
    const path = this.data.get('next-page-url')
    const $button = $(this.buttonTarget)

    each(this.propertyCheckboxTargets, checkbox => {
      const propertyId = checkbox.dataset.propertyId
      if (checkbox.checked) {
        if (!includes(ids, propertyId)) {
          ids.push(propertyId)
        }
      } else {
        remove(ids, (id) => id === propertyId)
      }
    })

    if (ids.length === 0) {
      $button
        .attr('disabled', 'disabled')
        .attr('href', '')
        .text(`Import ${ids.length} ${ids.length === 1 ? 'Property' : 'Properties'}`)
    } else {
      $button
        .attr('href', `${path}&pms_property_ids=${encodeURIComponent(ids.join(','))}`)
        .text(`Import ${ids.length} ${ids.length === 1 ? 'Property' : 'Properties'}`)
        .removeAttr('disabled')
    }
  }

  toggleSearchContainer() {
    $(this.searchContainerTarget).slideToggle()
  }
}
