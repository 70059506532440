import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'firstNameMergeField',
    'lastNameMergeField',
    'propertyNameMergeField',
    'propertyGroupMergeField',
    'title'
  ]

  connect() {
    this.load()
  }

  load() {
    this.titleTarget.addEventListener('input', this.refreshMergeFieldsButtons.bind(this));
    this.refreshMergeFieldsButtons()
  }

  click(e) {
    e.preventDefault()

    this.insertMergeField(e.target);
    this.titleTarget.dispatchEvent(new Event('input'));
    this.refreshMergeFieldsButtons();
  }

  insertMergeField(mergeFieldButton) {
    const textToInsert = mergeFieldButton.textContent.trim();
    const caretPosition = this.titleTarget.selectionStart;
    const currentValue = this.titleTarget.value;

    this.titleTarget.value = currentValue.slice(0, caretPosition) + textToInsert + currentValue.slice(caretPosition);
    this.titleTarget.setSelectionRange(caretPosition + textToInsert.length, caretPosition + textToInsert.length);
  }

  refreshMergeFieldsButtons() {
    if (this.titleTarget.value.includes('%%FIRSTNAME%%')) {
      this.selectMergeFieldButton(this.firstNameMergeFieldTarget);
    } else {
      this.unselectMergeFieldButton(this.firstNameMergeFieldTarget);
    }

    if (this.titleTarget.value.includes('%%LASTNAME%%')) {
      this.selectMergeFieldButton(this.lastNameMergeFieldTarget);
    } else {
      this.unselectMergeFieldButton(this.lastNameMergeFieldTarget);
    }

    if (this.hasPropertyNameMergeFieldTarget) {
      if (this.titleTarget.value.includes('%%PROPERTYNAME%%')) {
        this.selectMergeFieldButton(this.propertyNameMergeFieldTarget);
      } else {
        this.unselectMergeFieldButton(this.propertyNameMergeFieldTarget);
      }
    }

    if (this.hasPropertyGroupMergeFieldTarget) {
      if (this.titleTarget.value.includes('%%PROPERTYGROUP%%')) {
        this.selectMergeFieldButton(this.propertyGroupMergeFieldTarget);
      } else {
        this.unselectMergeFieldButton(this.propertyGroupMergeFieldTarget);
      }
    }
  }

  selectMergeFieldButton(button) {
    button.classList.remove('email-marketing-merge-field-button');
    button.classList.add('email-marketing-merge-field-button-selected');
  }

  unselectMergeFieldButton(button) {
    button.classList.remove('email-marketing-merge-field-button-selected');
    button.classList.add('email-marketing-merge-field-button');
  }
}
