import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'

export default class extends Controller {
  static targets = [
    'fileInput',
    'fileInputHidden',
    'clearButton',
    'fileName',
    'fileIcon',
    'uploadIcon',
    'placeholder',
    'message',
    'spinner',
    'urlInput'
  ]

  maxFileByteSize = 10000000

  connect() {
    if (this.hasFileInputTarget) {
      this.toggleName()
    }
  }

  onFileChange() {
    // remove the hidden input because we don't need it anymore
    // it's only there for when we reload the page after an error
    // to ensure the file gets resubmitted if the user doesn't change anything
    // but if they do change the file after getting an error, without this,
    // the original file would have been submitted instead of the new one
    // because this hidden field (which has the signed id of the original file)
    // would have taken precedence over the hidden field that the
    // ActiveStorage DirectUpload code would write when the user changes the file
    // the reason is simply that the ActiveStorage hidden field precedes this hidden
    // field in the HTML and has the same name, so when we submit the last input
    // with this name will be used.

    if (this.hasFileInputHiddenTarget) {
      $(this.fileInputHiddenTarget).remove()
    }

    const file = this.fileInputTarget.files[0]

    if (!file) {
      return
    }

    if (file.size >= this.maxFileByteSize) {
      $(this.fileInputTarget).val(null)

      return this.stopConfirmation('File must be less than 10 megabytes', false)
    }

    this.data.set('file-name', file.name)
    this.startConfirmation()
    this.toggleName()
    this.directUploadFile(file)
  }

  directUploadFile(file) {
    const upload = new DirectUpload(
      file,
      '/rails/active_storage/direct_uploads'
    )

    upload.create((error, blob) => {
      if (error) {
        this.stopConfirmation('We encountered an error while uploading your file, please try again or upload a different file', false)
      } else {
        this.uploadFile(blob.signed_id)
      }
    })
  }

  disableFormCta() {
    $(this.fileInputTarget).parents('form').find('input[type="submit"]').attr('disabled', 'disabled')
  }

  enableFormCta() {
    $(this.fileInputTarget).parents('form').find('input[type="submit"]').removeAttr('disabled')
  }

  startConfirmation() {
    this.disableFormCta()
    $(this.spinnerTarget).show()
    $(this.messageTarget).hide()
  }

  stopConfirmation(message, success) {
    this.enableFormCta()
    $(this.spinnerTarget).hide()
    $(this.messageTarget).show()
      .addClass(success ? 'text-green-500' : 'text-red-600')
      .removeClass(success ? 'text-red-600' : 'text-green-500')
      .text(message)
  }

  uploadFile(file) {
    $.ajax({
      url: this.data.get('url'),
      type: 'PUT',
      data: {
        file,
        home_page_template_tile_id: this.data.get('home-page-template-tile-id'),
        url: $(this.urlInputTarget).val()
      },
      success: (response) => {
        if (response.error) {
          this.stopConfirmation(response.error, false)
        } else {
          this.stopConfirmation(file ? 'File uploaded!' : 'File deleted!', !!file)
        }
      },
      error: () => {
        this.stopConfirmation('We encountered an error while uploading your file, please try again or upload a different file', false)
      }
    });
  }

  toggleName() {
    const fileName = this.data.get('file-name')

    if (fileName) {
      $(this.clearButtonTarget).show()
      $(this.fileIconTarget).show()
      $(this.uploadIconTarget).hide()
      $(this.fileNameTarget).text(fileName).show()
      $(this.placeholderTarget).hide()
    } else {
      $(this.clearButtonTarget).hide()
      $(this.fileIconTarget).hide()
      $(this.uploadIconTarget).show()
      $(this.fileNameTarget).text('').hide()
      $(this.placeholderTarget).show()
    }
  }

  clearFile() {
    if (this.hasFileInputHiddenTarget) {
      $(this.fileInputHiddenTarget).find('input').val(null)
    }

    this.uploadFile(null)
    $(this.fileInputTarget).val(null)
    this.data.set('file-name', '')
    this.toggleName()
    this.startConfirmation()
  }
}
