import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'splashPageSelect',
    'splashPagePreview'
  ]

  connect() {
    this.updatePreview()
  }

  updatePreview() {
    const $splashPageSelect = $(this.splashPageSelectTarget);
    const splashPageId = $splashPageSelect.val() || $splashPageSelect.find('option:selected').data('splash-page-id');
    const previewUrl = this.data.get('url-template').replace('SPLASH_PAGE_ID', splashPageId)

    if (splashPageId) {
      $(this.splashPagePreviewTarget).attr('src', previewUrl)
    } else {
      $(this.splashPagePreviewTarget).attr('src', '')
    }
  }
}
