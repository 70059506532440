import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'tilesContainer',
    'contactEmail',
    'contactPhone',
    'whatsappUrl',
    'showPropertyAddress',
    'contactButtonEnabled',
    'preview',
    'previewWrapper',
    'companyWebsite',
    'desktopPreviewButton',
    'mobilePreviewButton',
    'viatorCheckbox',
    'viatorDisabledText',
    'viatorEnabledText',
    'hostcoCheckbox',
    'hostcoDisabledText',
    'hostcoEnabledText',
    'form'
  ]

  initialize() {
    this.debouncedRefreshPreview = _.debounce(() => {
      this.refreshPreview()
    }, 350)
  }

  connect() {
    $(this.previewTargets[0]).addClass('ondeck-preview')

    $(this.previewTargets).on('load', (e) => {
      if ($(e.target).attr('src')) {
        this.previewTargets.forEach((el, i) => {
          $(el).toggleClass('ondeck-preview')
        })
      }
    })

    this.showMobilePreview()
    this.refreshPreview()

    this.onViatorChange()
    this.onHostcoChange()
  }

  moveUp(e) {
    const currentTile = $(e.currentTarget).parents('.ordered-tile')
    const prevTile = currentTile.prev()

    if (prevTile.length) {
      currentTile.insertBefore(prevTile)
      this.updateTilesOrder()
    }
  }

  moveDown(e) {
    const currentTile = $(e.currentTarget).parents('.ordered-tile')
    const nextTile = currentTile.next()

    if (nextTile.length) {
      currentTile.insertAfter(nextTile)
      this.updateTilesOrder()
    }
  }

  deleteCustomButton(e) {
    const currentTile = $(e.currentTarget).parents('.ordered-tile')

    currentTile.remove()
    this.onChange()
  }

  showCustomButtonDeletionConfirmation(e) {
    const currentTile = $(e.currentTarget).parents('.ordered-tile')

    currentTile.find('.custom-button-deletion-confirmation').show()
  }

  hideCustomButtonDeletionConfirmation() {
    $('.custom-button-deletion-confirmation').hide()
  }

  addCustomButton() {
    fetch(this.data.get('url'))
      .then(response => response.text())
      .then(form => {
        $(this.tilesContainerTarget).append(form)
        this.updateTilesOrder()
      })
  }

  updateTilesOrder() {
    $(this.tilesContainerTarget).find('.order-input').each((index, input) => {
      $(input).val(index + 1)
    })

    this.onChange()
  }

  onChange() {
    this.debouncedRefreshPreview()
  }

  onViatorChange() {
    if (!this.hasViatorCheckboxTarget) {
      return
    }

    if (this.viatorCheckboxTarget.checked || this.viatorCheckboxTarget.value === 'true') {
      $(this.viatorDisabledTextTarget).hide()
      $(this.viatorEnabledTextTarget).show()
    } else {
      $(this.viatorDisabledTextTarget).show()
      $(this.viatorEnabledTextTarget).hide()
    }

    this.onChange()
  }

  onHostcoChange() {
    if (!this.hasHostcoCheckboxTarget) {
      return
    }

    if (this.hostcoCheckboxTarget.checked || this.hostcoCheckboxTarget.value === 'true') {
      $(this.hostcoDisabledTextTarget).hide()
      $(this.hostcoEnabledTextTarget).show()
    } else {
      $(this.hostcoDisabledTextTarget).show()
      $(this.hostcoEnabledTextTarget).hide()
    }

    this.onChange()
  }

  onSubmit(e) {
    if (!this.upsellModalViewed && !this.hostcoCheckboxTarget.checked && !this.viatorCheckboxTarget.checked) {
      this.upsellModalViewed = true

      document.dispatchEvent(new CustomEvent('show_modal', {
        detail: {
          url: this.data.get('vendor-confirmation-path')
        }
      }))

      $(this.formTarget).find('input[type="submit"]').removeAttr('disabled')

      e.preventDefault()
      e.stopPropagation()
    }
  }

  enableVendors() {
    this.hostcoCheckboxTarget.value = true
    this.viatorCheckboxTarget.value = true

    this.submitForm()
  }

  submitForm() {
    this.formTarget.submit()
  }

  showDesktopPreview() {
    const width = `${$(this.previewWrapperTarget).width() * 2}px` // multiplying by 2 because the iframe is scaled to 0.5
    $(this.desktopPreviewButtonTarget).removeClass('bg-white').addClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).removeClass('bg-gray-200').addClass('bg-white')
    $(this.previewTargets).css('width', width).addClass('w-full scaled-frame')
  }

  showMobilePreview() {
    $(this.desktopPreviewButtonTarget).addClass('bg-white').removeClass('bg-gray-200')
    $(this.mobilePreviewButtonTarget).addClass('bg-gray-200').removeClass('bg-white')
    $(this.previewTargets).css('width', '23rem').removeClass('w-full scaled-frame')
  }

  refreshPreview() {
    const src = `${this.data.get('preview-path')}?${$.param(this.previewParams)}`

    $(this.previewTargets).filter('.ondeck-preview').attr('src', src)
  }

  get previewParams() {
    const tiles = []
    $('.ordered-tile').each((i, tile) => {
      tiles.push({
        order: $(tile).find('.order-input').val(),
        type: $(tile).find('.tile-type').val(),
        icon: $(tile).find('.icon-field').val(),
        display_name: $(tile).find('.display-name').val(),
        subtext: $(tile).find('.subtext').val(),
        active: $(tile).find('.active-input').val()
      })
    })

    return {
      contact_email: this.hasContactEmailTarget && this.contactEmailTarget.value,
      contact_phone: this.hasContactPhoneTarget && this.contactPhoneTarget.value,
      whatsapp_url: this.hasWhatsappUrlTarget && this.whatsappUrlTarget.value,
      contact_button_enabled: this.hasContactButtonEnabledTarget && this.contactButtonEnabledTarget.checked,
      show_property_address: this.hasShowPropertyAddressTarget && this.showPropertyAddressTarget.checked,
      tiles
    }
  }
}
